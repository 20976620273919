/**
 * 活动标签相关API
 */

import request from '@/utils/request'
import { param } from 'jquery'

/**
 * 添加活动标签
 * @param params
 */
export function activeLabelAdd(params) {
  return request({
    url: '/active/label/add',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 查询活动标签列表
 * @param params
 */
export function activeLabelShopPage(params) {
  return request({
    url: '/active/label/shop_page',
    method: 'post',
    params
  })
}

/**
 * 查询店铺标签
 * @param params
 */
export function getShopTag(params) {
  return request({
    url: 'active/label/shop_list',
    method: 'post',
    params
  })
}

/**
 * 修改标签
 * @param params
 */
export function activeLabelEdit(params) {
  return request({
    url: '/active/label/edit',
    method: 'post',
    params
  })
}

/**
 * 删除标签
 * @param id
 */
export function activeLabelDel(id) {
  return request({
    url: `/active/label/${id}`,
    method: 'delete',
  })
}

/**
 * 一次性查询全部平台标签列表
 * @param label_type
 */
export function activeLabelList(params) {
  return request({
    url: '/active/label/list',
    method: 'post',
    data: params
  })
}

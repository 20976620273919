<template>
  <div class="img-uploader" :class="{
    limited,
    more_than_two: fileList$.length > 2 || (fileList$.length === limit),
    no_title: !title,
  }">
    <el-upload :action="action" :limit="limit" :file-list.sync="fileList$" :on-change="onChange" :on-error="onError"
      :before-upload="beforeUpload" list-type="picture-card">
      <div slot="default" class="default-button">
        <i class="el-icon-plus"></i>
        <div class="placeholder" v-if="placeholder">{{ placeholder }}</div>
      </div>
      <div slot="file" slot-scope="{file}" class="file-wrapper"
        :style="{ backgroundImage: `url(${file.local_url || file.url})` }">
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="sortFile(file, false)" v-if="sortable && file.sort > 0">
            <i class="el-icon-back"></i>
          </span>
          <span class="el-upload-list__item-delete" @click="removeFile(file)">
            <i class="el-icon-delete"></i>
          </span>
          <span class="el-upload-list__item-delete" @click="sortFile(file, true)"
            v-if="sortable && file.sort < fileList$.length - 1">
            <i class="el-icon-right"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <div class="uploader-title" v-if="title">{{ title }}</div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { api } from "@/libs/common/domain";

const defaultAction = api.base + '/uploaders';

export default @Component({ name: 'img-uploader' })
class ImgUploader extends Vue {
  @Prop({ default: defaultAction }) action;
  @Prop({ default: 9 }) limit;
  @Prop({ default: () => [] }) fileList;
  @Prop({ default: '上传图片' }) placeholder;
  @Prop({ default: true }) sortable;
  @Prop({ default: '' }) title;

  fileList$ = [];
  limited = false;
  removing = false;

  @Watch('fileList')
  fileListChanged (fileList) {
    this.fileList$ = mkSortable(fileList);
    if (!this.removing) {
      this.checkLimited();
    }
  }

  created () {
    this.fileList$ = mkSortable(this.fileList);
    this.checkLimited();
  }

  mounted () {
  }

  checkLimited () {
    this.limited = this.fileList$.length >= this.limit;
  }

  onChange (file, fileList = []) {
    this.fileList$ = mkSortable(fileList);
    this.$emit('update:fileList', this.fileList$);
    this.checkLimited();
  }

  onError (err, file, _fileList) {
    this.$message.error('图片上传失败！');
    console.log('upload error', err, file);
    const fileList = [...this.fileList$].filter(f => f.url !== file.url);
    this.fileList$ = mkSortable(fileList);
    this.$emit('update:fileList', this.fileList$);
  }

  removeFile (file) {
    this.removing = true;
    const fileList = [...this.fileList$];
    fileList[file.sort] = null;
    this.fileList$ = mkSortable(fileList);
    this.$emit('update:fileList', this.fileList$);
    setTimeout(() => {
      this.checkLimited();
      this.removing = false;
    }, 400);
  }

  sortFile (file, forward = false) {
    if (forward) {
      // 前进
      if (file.sort >= this.fileList$.length - 1) return;
      const next = this.fileList$[file.sort + 1];
      file.sort += 1;
      next.sort -= 1;
    } else {
      // 后退
      if (file.sort <= 0) return;
      const prev = this.fileList$[file.sort - 1];
      file.sort -= 1;
      prev.sort += 1;
    }
    this.fileList$ = resort(this.fileList$);
    this.$emit('update:fileList', this.fileList$);
  }

  beforeUpload (file) {
    const isImg = file.type.indexOf("image") > -1;
    if (!isImg) this.$message.error("只能上传图片");
    return isImg;
  }
}

function mkSortable (arr = []) {
  if (!arr) return [];
  return arr.map((item) => {
    if (!item) return null;

    if (item.response) {
      if (item.url.includes('blob')) {
        item.local_url = item.url;
      }
      item.url = item.response.url;
    }
    return item;
  }).filter(item => !!item).map((item, index) => {
    item.sort = index;
    return item;
  });
}

function resort (arr = []) {
  return [...arr].sort((a, b) => {
    return a.sort - b.sort;
  });
}
</script>

<style lang="scss">
.img-uploader {
  padding: 8px 8px 4px 8px;
  border-radius: 8px;
  border: 1px solid #F6EED0;

  &.limited {
    div.el-upload {
      display: none;
    }
  }

  &.no_title {
    padding-bottom: 0;
  }

  &.more_than_two {
    .el-upload-list__item {
      &:last-child {
        margin-right: 0;
      }
    }

    .el-upload--picture-card {
      margin-left: 8px;
    }
  }

  .uploader-title {
    text-align: center;
    color: #888;
    line-height: 1.5;
  }

  .el-upload-list {
    display: inline-block;
    line-height: 1;

    .el-upload-list__item {
      width: 110px;
      height: 110px;
      line-height: 1;

      .file-wrapper {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }

  .el-upload--picture-card {
    width: 110px;
    height: 110px;
    line-height: 1;
    vertical-align: auto;
    position: relative;
    margin: 0 0 8px 0;

    .default-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      .placeholder {
        margin-top: 8px;
        color: #8C939D;
        font-size: 90%;
      }
    }
  }
}
</style>

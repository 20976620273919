/**
 * 活动草稿相关API
 */

import request from '@/utils/request'

/**
 * 添加活动草稿
 * @param params
 */
export function activeDraftGoodsActivityAdd(data) {
  return request({
    url: '/active/draftGoodsActivity/add',
    method: 'post',
    headers: { "Content-Type": "application/json" },
    data
  })
}

/**
 * 查询活动草稿列表
 * @param params
 */
export function activeDraftGoodsActivityShopPage(params) {
  return request({
    url: '/active/draftGoodsActivity/shop_page',
    method: 'post',
    params
  })
}

/**
 * 删除活动草稿
 * @param id
 */
export function activeDraftGoodsActivityDel(id) {
  return request({
    url: `/active/draftGoodsActivity/${id}`,
    method: 'delete'
  })
}

/**
 * 草稿编辑回显
 * @param id
 */
export function activeDraftGoodsActivityShow(id) {
  return request({
    url: `/active/draftGoodsActivity/${id}`,
    method: 'get'
  }).then(resp => {
    resp.info = resp.intro;
    return resp;
  });
}

/**
 * 导师相关API
 */

import request from '@/utils/request'

/**
 * 新增导师
 * @param params
 */
export function activeTeacherAdd(params) {
  return request({
    url: '/active/teacher/add',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 查询全部导师列表
 * @param
 */
export function activeTeacherShopList() {
  return request({
    url: '/active/teacher/shop_list',
    method: 'post',
    loading: false
  })
}

/**
 * 删除导师
 * @param id
 */
export function activeTeacherDel(id) {
  return request({
    url: `/active/teacher/${id}`,
    method: 'delete',
    loading: false
  })
}

/**
 * 修改导师信息
 * @param params
 */
export function activeTeacherEdit(params) {
  return request({
    url: '/active/teacher/edit',
    method: 'post',
    loading: false,
    params
  })
}

<template>
  <el-cascader v-if="props" :props="props" class="address-selector" @change="change" v-model="value$"></el-cascader>
</template>

<script>
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import * as api from './address.api';

const AddressCaches = {
  map: {},
  requestMap: {},
};

export default @Component({
  name: 'address-selector'
})
class AddressSelector extends Vue {
  @Prop() value;
  @Prop() level;
  value$ = [];
  props = null;

  mounted() {
    if (this.value) {
      this.value$ = this.value.filter(item => item > 0);
      this.initProp();
    }
  }

  @Watch('value')
  valueChange(e) {
    if (e) {
      e = e.filter(item => item > 0);
      this.value$ = e;
      this.initProp();
    }
  }

  initProp() {
    if (!this.value$) return;
    if (this.props) return;
    this.props = {
      lazy: true,
      lazyLoad: (node, resolve) => {
        const id = node.root ? node.level : node.data.value;

        if (AddressCaches.requestMap[id]) {
          setTimeout(() => {
            resolve(AddressCaches.requestMap[id]);
          }, 250);
        } else {
          api.getAddress(id).then(resp => {
            const results = resp.map(item => {
              AddressCaches.map[item.id] = item;
              let leaf = node.level === (this.level || 2);
              if (!item.isnext) leaf = true;
              return {
                value: item.id,
                label: item.local_name,
                leaf,
              };
            });

            AddressCaches.requestMap[id] = results;

            resolve(results);
          });
        }
      },
    }
  }

  change(e) {
    this.$emit('change', e.map(i => AddressCaches.map[i]));
  }
}
</script>

<style lang="scss">
.address-selector {
  width: 300px;
}
</style>

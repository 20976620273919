<template>
  <div class="content" v-loading="loading" @click="blur">
    <div class="step_div text-center row no-gutters align-items-center">
      <div class="step1 col">
        <div :class="activestep === 0 ? 'step' : ''" class="line"></div>
        <div :class="activestep === 0 ? 'box_border' : ''" class="box1">
          <div :class="activestep === 0 ? 'step' : ''" class="box2">1</div>
          <div :class="activestep === 0 ? 'stepword' : ''" class="tishixinxi">
            编辑基本信息
          </div>
        </div>
      </div>
      <div class="step2 col">
        <div :class="activestep === 1 ? 'step' : ''" class="line"></div>
        <div :class="activestep === 1 ? 'box_border' : ''" class="box1">
          <div :class="activestep === 1 ? 'step' : ''" class="box2">2</div>
          <div :class="activestep === 1 ? 'stepword' : ''" class="tishixinxi">
            票种信息
          </div>
        </div>
      </div>
      <div class="step3 col">
        <div :class="activestep === 2 ? 'step' : ''" class="line"></div>
        <div :class="activestep === 2 ? 'box_border' : ''" class="box1">
          <div :class="activestep === 2 ? 'step' : ''" class="box2">3</div>
          <div :class="activestep === 2 ? 'stepword' : ''" class="tishixinxi">
            活动详情
          </div>
        </div>
      </div>
    </div>
    <el-form :model="baseInfoForm" status-icon label-position="right" ref="baseInfoForm" label-width="160px"
      class="demo-ruleForm" :rules="baseInfoFormRules">
      <!--步骤1-->
      <div class="content-goods-publish" v-show="activestep === 0">
        <!--基本信息-->
        <div class="base-info-item" style="padding-bottom: 80px">
          <div class="step1_title">
            <div style="
                width: 12px;
                height: 12px;
                background: #007f7f;
                border-radius: 50%;
                margin-top: 3px;
              "></div>
            <h4>基本信息</h4>
          </div>
          <div>
            <el-form-item label="活动名称：" prop="activity_name">
              <el-input v-model="baseInfoForm.activity_name" class="ipt-default width" placeholder="请输入活动名称"
                maxlength="32" :disabled="!!isUp"></el-input>
            </el-form-item>
            <el-form-item label="活动分类：" prop="activityTypeChoose">
              <el-cascader expand-trigger="hover" :options="activityTypeList" @change="activityTypeChange"
                v-model="activityTypeChoose" :props="{ checkStrictly: true }" :disabled="!!isUp"></el-cascader>
            </el-form-item>
            <el-form-item label="活动时长：" prop="activityTime">
              <div style="display: flex">
                <el-radio v-model="activityTime.activityTimeType" label="1" @change="activityTimeClick(0)">
                  <el-input v-model="activityTime.activityTimeDay" class="ipt-default" placeholder="请输入天数"
                    style="width: 125px" maxlength="32" :disabled="activityTime.activityTimeType !== '1'"></el-input>
                  天
                </el-radio>
                <el-radio v-model="activityTime.activityTimeType" label="2" @change="activityTimeClick(1)">
                  <el-input v-model="activityTime.activityTimeHour" class="ipt-default" placeholder="请输入小时数"
                    style="width: 140px" maxlength="32" :disabled="activityTime.activityTimeType !== '2' ? true : false
    "></el-input>
                  小时
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="年龄：" prop="child_limit">
              <div style="display: flex; align-items: center">
                <el-radio v-model="baseInfoForm.child_limit_sign" :label="2" style="display: flex; align-items: center">
                  <div style="display: flex; align-items: center">
                    <el-input v-model="baseInfoForm.child_limit_gte" class="ipt-default" placeholder="下限"
                      style="width: 70px" maxlength="2" :disabled="baseInfoForm.child_limit_sign !== 2 ? true : false
    "></el-input>
                    <span style="color: #aaa; margin-right: 10px">至</span>
                    <el-input v-model="baseInfoForm.child_limit_lt" class="ipt-default" placeholder="上限"
                      style="width: 70px" maxlength="2" :disabled="baseInfoForm.child_limit_sign !== 2 ? true : false
    "></el-input>
                    <span style="color: #222">岁</span>
                    <span style="color: #aaa"></span>
                  </div>
                </el-radio>
                <el-radio v-model="baseInfoForm.child_limit_sign" :label="1" @change="childLimitSignClick(1)">不限
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="参与方式：" prop="partake">
              <div style="width: 600px">
                <el-radio v-model="baseInfoForm.partake" :label="1">独立参加
                </el-radio>
                <el-radio v-model="baseInfoForm.partake" :label="2">家属随行
                </el-radio>
                <el-radio v-model="baseInfoForm.partake" :label="3">不限
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="地址类型：" prop="addr_type">
              <div style="width: 600px">
                <el-radio v-model="baseInfoForm.addr_type" :label="1">集合地
                </el-radio>
                <el-radio v-model="baseInfoForm.addr_type" :label="2">活动地点
                </el-radio>
                <el-radio v-model="baseInfoForm.addr_type" :label="3">店铺地址
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="详细地址：" prop="activityAddress">
              <div class="addressBox">
                <div>
                  <el-select v-model="activityAddress" style="width: 400px; margin-right: 10px" filterable clearable
                    placeholder="请选择" @change="address('ok')">
                    <el-option v-for="item in activityAddressList" :key="item.id" :label="[
    item.province,
    item.city,
    item.country,
    item.addr_detail,
  ]
    .filter((s) => !!s)
    .join('')
    " :value="item.id"></el-option>
                  </el-select>
                  <!-- <el-button
                    class="btn-default"
                    type="primary"
                    @click="address('ok')"
                    size="mini"
                  >确定
                  </el-button> -->
                  <el-button class="btn-default" type="primary" @click="address('new')" size="mini">新增地址
                  </el-button>
                </div>
                <el-input v-if="isNewAddress === false" style="width: 550px; margin-top: 10px; color: #333"
                  type="textarea" :rows="2" placeholder="请选择地址" :value="addressDetails" resize="none"
                  :disabled="true"></el-input>
              </div>
              <div class="addressNewBox" v-if="isNewAddress">
                <el-form-item label="请选择地区：">
                  <div>
                    <address-selector :value="address2" @change="handleChangeArea"></address-selector>
                  </div>
                </el-form-item>
                <el-form-item label="详细地址：">
                  <el-input v-model="addressNewParams.addr_detail" class="ipt-default" style="width: 460px"
                    placeholder="请录入详细地址便于地图定位 例：中关村大街18号B座中关村互联网教育创新中心1901室"></el-input>
                </el-form-item>
                <el-form-item label=" ">
                  <el-button class="btn-default" type="primary" @click="address('save')" size="mini">保存地址
                  </el-button>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="客服电话：" prop="service_phone">
              <div style="display: flex; width: 610px">
                <el-input v-model="baseInfoForm.service_phone1" class="ipt-default"
                  style="width: 300px; margin-right: 20px" placeholder="示例：010-12345678" maxlength="13" :disabled="isUp"
                  @input="phone1Change" clearable></el-input>
                <el-input v-model="baseInfoForm.service_phone2" class="ipt-default" style="width: 300px"
                  placeholder="示例：13512345678" maxlength="11" :disabled="isUp" @input="phone2Change"
                  clearable></el-input>
              </div>
            </el-form-item>
            <el-form-item label="营业时间显示：">
              <div style="width: 600px">
                <el-radio v-model="baseInfoForm.worktime_sign" :disabled="isUp" :label="1">是
                </el-radio>
                <el-radio v-model="baseInfoForm.worktime_sign" :disabled="isUp" :label="0">否
                </el-radio>
                <span style="color: #888; font-size: 14px">* 若显示，请先在店铺管理中维护营业时间</span>
              </div>
            </el-form-item>
            <el-form-item label="活动标签：" prop="activityTagChooseList">
              <div style="width: 600px; display: flex" @click.stop="focus(1)">
                <div style="
                    border: 1px solid rgba(221, 221, 221, 1);
                    border-radius: 8px;
                    padding: 5px;
                    width: 550px;
                    margin-right: 10px;
                  ">
                  <el-tag style="background: #008080; color: #fff; margin-right: 10px" :key="index"
                    v-for="(item, index) in activityTagChooseList" closable @close="tagClose(index, 'activity')">{{
    item.label_name }}
                  </el-tag>
                </div>
                <el-button type="text" @click.stop="activityTagShow = !activityTagShow">添加标签
                </el-button>
              </div>
              <div class="activityTagBox" v-if="activityTagShow" @click.stop="focus(1)">
                <el-form-item label="平台标签：">
                  <div class="tagBox">
                    <el-tag class="tag" :key="index" v-for="(item, index) in activityTagPlatformList"
                      @click="TagToChoose(item, 'activity')">{{ item.label_name }}
                    </el-tag>
                  </div>
                </el-form-item>
                <el-form-item label="历史标签：">
                  <div class="tagBox">
                    <el-tag class="tag" :key="index" v-for="(item, index) in activityTagList"
                      @click="TagToChoose(item, 'activity')">{{ item.label_name }}
                    </el-tag>
                  </div>
                </el-form-item>
                <el-form-item label="自定义标签：">
                  <div style="display: flex; width: 450px">
                    <el-input v-model="activityNewTag" class="ipt-default" placeholder="请输入标签名称"
                      maxlength="32"></el-input>
                    <el-button class="btn-default" type="primary" @click="addNewTag('activity')" size="mini">添加
                    </el-button>
                  </div>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="能力标签：" prop="abilityTagChooseList">
              <div style="width: 600px; display: flex" @click.stop="focus(2)">
                <div style="
                    border: 1px solid rgba(221, 221, 221, 1);
                    border-radius: 8px;
                    padding: 5px;
                    width: 550px;
                    margin-right: 10px;
                  ">
                  <el-tag style="background: #008080; color: #fff; margin-right: 10px" :key="index"
                    v-for="(item, index) in abilityTagChooseList" closable @close="tagClose(index, 'ability')">{{
    item.label_name }}
                  </el-tag>
                </div>
                <el-button type="text" @click.stop="abilityTagShow = !abilityTagShow">添加标签
                </el-button>
              </div>
              <div class="abilityTagBox" v-if="abilityTagShow" @click.stop="focus(2)">
                <el-form-item label="平台标签：">
                  <div class="tagBox">
                    <el-tag class="tag" :key="index" v-for="(item, index) in abilityTagPlatformList"
                      @click="TagToChoose(item, 'ability')">{{ item.label_name }}
                    </el-tag>
                  </div>
                </el-form-item>
                <el-form-item label="历史标签：">
                  <div class="tagBox">
                    <el-tag class="tag" :key="index" v-for="(item, index) in abilityTagList"
                      @click="TagToChoose(item, 'ability')">{{ item.label_name }}
                    </el-tag>
                  </div>
                </el-form-item>
                <el-form-item label="自定义标签：">
                  <div style="display: flex; width: 450px">
                    <el-input v-model="abilityNewTag" class="ipt-default" placeholder="请输入标签名称"
                      maxlength="32"></el-input>
                    <el-button class="btn-default" type="primary" @click="addNewTag('ability')" size="mini">添加
                    </el-button>
                  </div>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="使用优惠券：" prop="coupon_sign">
              <div style="width: 600px">
                <el-radio v-model="baseInfoForm.coupon_sign" :disabled="isUp" :label="1">是
                </el-radio>
                <el-radio v-model="baseInfoForm.coupon_sign" :disabled="isUp" :label="0">否
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="填写出行人：" prop="traveler_sign">
              <div style="width: 600px">
                <el-radio-group v-model="baseInfoForm.traveler_sign" :disabled="isUp" @change="travelerChange">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </div>
              <div class="travelerTagBox" v-if="baseInfoForm.traveler_sign === 1">
                <el-form-item label="出行人信息：">
                  <div class="tagBox">
                    <el-tag class="tagNo" :class="travelerList[0] === 1 ? 'tagYes' : ''" @click="traveler(0)">出行人姓名
                    </el-tag>
                    <!-- <el-tag class="tagNo" :class="travelerList[1] === 1 ? 'tagYes' : ''" @click="traveler(1)">出行人英文姓
                    </el-tag>
                    <el-tag class="tagNo" :class="travelerList[2] === 1 ? 'tagYes' : ''" @click="traveler(2)">出行人英文名
                    </el-tag>
                    <el-tag class="tagNo" :class="travelerList[3] === 1 ? 'tagYes' : ''" @click="traveler(3)">出行人证件类型
                    </el-tag>
                    <el-tag class="tagNo" :class="travelerList[4] === 1 ? 'tagYes' : ''" @click="traveler(4)">出行人证件号
                    </el-tag>
                    <el-tag class="tagNo" :class="travelerList[5] === 1 ? 'tagYes' : ''" @click="traveler(5)">出行人证件有效期
                    </el-tag>
                    <el-tag class="tagNo" :class="travelerList[6] === 1 ? 'tagYes' : ''" @click="traveler(6)">出行人国籍
                    </el-tag> -->
                    <el-tag class="tagNo" :class="travelerList[7] === 1 ? 'tagYes' : ''" @click="traveler(7)">出行人手机号
                    </el-tag>
                    <!-- <el-tag
                      class="tagNo"
                      :class="travelerList[8]===1?'tagYes':''"
                      @click="traveler(8)"
                    >生日
                    </el-tag>
                    <el-tag
                      class="tagNo"
                      :class="travelerList[9]===1?'tagYes':''"
                      @click="traveler(9)"
                    >性别
                    </el-tag> -->
                    <!-- <el-tag class="tagNo" :class="travelerList[10] === 1 ? 'tagYes' : ''" @click="traveler(10)">
                      出行人类型
                    </el-tag> -->
                  </div>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="填写紧急联系人：" class="urgentBox" prop="emergency_contact_sign">
              <div style="width: 600px">
                <el-radio v-model="baseInfoForm.emergency_contact_sign" :disabled="isUp" :label="1">是
                </el-radio>
                <el-radio v-model="baseInfoForm.emergency_contact_sign" :disabled="isUp" :label="0">否
                </el-radio>
              </div>
            </el-form-item>

            <el-form-item label="产品轮播图：" prop="bannerListVal">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="upload-card">
                    <img-uploader :limit="1" :file-list.sync="bannerList1" title="封面图(1张, 必传)"></img-uploader>
                  </div>
                </div>

                <div class="col-auto">
                  <div class="upload-card">
                    <img-uploader :limit="4" :file-list.sync="bannerList2" title="轮播图(4张, 选传)"></img-uploader>
                  </div>
                </div>

                <div class="col"></div>
              </div>

              <div class="imgExplain">
                <span>* 第1张默认为封面图， 必传。其他4张轮播图选传。</span><br />
                <span>* 图片尺寸800*600像素，图片大小 小于3M</span><br />
                <span>* 图片格式：JPG、JPEG、GIF、PNG</span>
              </div>
            </el-form-item>

            <el-form-item label="广告位图：">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="upload-card">
                    <img-uploader :limit="3" :file-list.sync="advertList" title="广告位(3张, 选传)"></img-uploader>
                  </div>
                </div>
                <div class="col"></div>
              </div>

              <div class="imgExplain">
                <!-- <span>* 广告位图片上传请先联系平台管理员</span><br /> -->
                <span>* 图片尺寸400*400像素，图片大小小于3M</span><br />
                <span>* 图片格式：JPG、JPEG、GIF、PNG</span>
              </div>
            </el-form-item>
            <el-form-item label="活动视频：">
              <div class="activityVideoBox">
                <el-upload :action="MixinUploadApi" :show-file-list="false" :on-success="upLoadVideoSuccess"
                  :before-upload="upLoadVideoBefore" :on-progress="uploadLoading">
                  <div class="activityUploadBox" v-if="baseInfoForm.video === ''">
                    <div v-if="videoUpload">
                      <el-progress :text-inside="true" :stroke-width="16" :percentage="videoProgress"
                        :color="progressColor" status="success"></el-progress>
                    </div>
                    <div v-else>
                      <i class="el-icon-plus avatar-uploader-icon"></i>
                      <div>上传视频</div>
                    </div>
                  </div>
                  <div class="activityUploadVideoBox" v-if="baseInfoForm.video != ''">
                    <video :src="baseInfoForm.video"></video>
                    <img src="/img/sctp@2x.png" class="imgDel" @click.stop="videoRemove" />
                  </div>
                </el-upload>
                <div class="explain">
                  * 用于显示在活动详情页，视频不大于50M, 视频仅支持MP4
                </div>
              </div>
            </el-form-item>
            <el-form-item label="导师信息：">
              <div style="display: flex; align-items: flex-end">
                <el-select v-model="teacherChooseList" style="width: 150px" placeholder="请选择导师" multiple
                  @change="teacherChange">
                  <el-option v-for="item in activityTutorList" :key="item.id" :label="item.teacher_name"
                    :value="item.id"></el-option>
                </el-select>
                <div class="explain">
                  * 若选择导师，需先在店铺设置中进行维护
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </div>
      <!--步骤2-->
      <div class="content-goods-publish" v-show="activestep === 1">
        <!--票种信息-->
        <div class="base-info-item">
          <div class="step1_title">
            <div style="
                width: 12px;
                height: 12px;
                background: #007f7f;
                border-radius: 50%;
                margin-top: 3px;
              "></div>
            <h4>票种信息</h4>
          </div>
          <div>
            <el-form-item label="票种数量：" prop="sale_limit_sign">
              <div style="width: 600px">
                <el-radio v-model="baseInfoForm.sale_limit_sign" :disabled="isUp" :label="1">不限
                </el-radio>
                <el-radio v-model="baseInfoForm.sale_limit_sign" :disabled="isUp" :label="2">
                  限制总预定数
                  <el-input v-model="baseInfoForm.sale_limit_num" class="ipt-default" style="width: 100px"
                    :disabled="isUp"></el-input>
                </el-radio>
                <el-radio v-model="baseInfoForm.sale_limit_sign" :disabled="isUp" :label="3">
                  按票种限制每场预订数
                  <span style="font-size: 12px; color: #999; margin-left: 7px">如话剧、演出</span>
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="规格票种设置：" prop="ticket_query_vos">
              <div class="ticketTypeBox" v-for="(ticketTypeItem,
    ticketTypeIndex) in baseInfoForm.ticket_query_vos" :key="ticketTypeIndex">
                <div class="ticketTypeTopBox">
                  <div class="ticketTypeTopTitleBox">
                    <div class="left">
                      <div></div>
                      <span>票种{{ ticketTypeIndex + 1 }}</span>
                    </div>
                    <div class="right">
                      <el-button type="text" class="copyButton" @click="copyTicket(ticketTypeItem, ticketTypeIndex)"
                        v-if="!isUp">复制</el-button>
                      <el-button type="text" class="delButton" v-if="ticketTypeIndex !== 0 && !isUp"
                        @click="addAndDelTicketType('del', ticketTypeIndex)">删除</el-button>
                    </div>
                  </div>
                  <el-input v-model="ticketTypeItem.ticket_name" class="ticketTypeIpt" placeholder="添加票种名称"
                    :disabled="isUp"></el-input>
                  <el-input v-model="ticketTypeItem.ticket_info" class="ticketTypeIpt" placeholder="添加票种描述(非必填)"
                    :disabled="isUp"></el-input>
                  <el-input v-model="ticketTypeItem.fee_info" class="ticketTypeIpt" placeholder="添加费用描述(必填)"
                    :disabled="isUp"></el-input>
                  <div class="info">
                    <div>
                      <div class="name">原价</div>
                      <div class="inpBox">
                        <el-input v-model="ticketTypeItem.mktprice" class="inp" @change="mktpriceChange"
                          :disabled="isUp"></el-input>
                        <div>元</div>
                      </div>
                    </div>
                    <div>
                      <div class="name">销售价</div>
                      <div class="inpBox">
                        <el-input v-model="ticketTypeItem.price" class="inp" @change="priceChange"
                          :disabled="isUp"></el-input>
                        <div>元</div>
                      </div>
                    </div>
                    <!-- <div>
                      <div class="name">单场可售数</div>
                      <div class="inpBox">
                        <el-input v-model="ticketTypeItem.single_sale_num" class="inp"></el-input>
                        <div>份</div>
                      </div>
                    </div>-->
                    <div>
                      <div class="name">用户限购</div>
                      <div class="selectBox">
                        <el-select v-model="ticketTypeItem.limit_sign" class="select" filterable clearable
                          placeholder="请选择" :disabled="isUp" @change="ticketTypeItem.buy_limit_num = ''">
                          <el-option v-for="item in quotaTypeList" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div v-if="ticketTypeItem.limit_sign === 1">
                      <div class="name">用户限购数量</div>
                      <div class="inpBox">
                        <el-input v-model="ticketTypeItem.buy_limit_num" class="inp"></el-input>
                        <div>份</div>
                      </div>
                    </div>
                    <div>
                      <div class="name">期次类型</div>
                      <div class="selectBox">
                        <el-select v-model="ticketTypeItem.multi_period" class="select" filterable placeholder="请选择"
                          @change="periodType(ticketTypeIndex)" :disabled="isUp">
                          <el-option v-for="item in periodTypeList" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ticketTypeBottomBox" v-if="ticketTypeItem.multi_period === 1">
                  <div class="ticketTypeBottom" :class="ticketPeriodIndex === 0 ? 'noBorder' : ''"
                    v-for="(ticketPeriodItem, ticketPeriodIndex) in ticketTypeItem.goods_ticket_date_vos"
                    :key="ticketPeriodIndex">
                    <!-- start -->
                    <div class="titleBox">
                      <img src="/img/hdqc@2x.png" alt="" />
                      <div>第{{ ticketPeriodItem.ticket_num }}期</div>
                    </div>
                    <div class="dateAndTimeBox">
                      <el-form-item label="活动日期：" class="formItem">
                        <el-date-picker style="width: 300px"
                          v-model="ticketTypeList[ticketTypeIndex].ticketPeriodDates[ticketPeriodIndex].dates"
                          type="daterange" align="center" :editable="false" size="small" range-separator="-"
                          start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"
                          :picker-options="datePickerOptions"
                          @change="ticketPeriodData(ticketTypeIndex, ticketPeriodIndex)"
                          :disabled="isUp && !periodEditor.isEditing"></el-date-picker>
                      </el-form-item>
                      <div style="display: flex">
                        <el-button type="text" style="color: #3471cd"
                          @click="addAndDelTicketPeriod('add', ticketTypeIndex)" v-if="ticketPeriodIndex === ticketTypeItem.goods_ticket_date_vos.length - 1 &&
    (!isUp)
    ">+ 其他日期
                        </el-button>
                        <el-button type="text" style="color: rgba(254, 85, 88, 1)" @click="
    addAndDelTicketPeriod(
      'del',
      ticketTypeIndex,
      ticketPeriodIndex
    )
    " v-if="ticketPeriodIndex !== 0 && (!isUp)">
                          删除
                        </el-button>
                      </div>
                    </div>
                    <div v-if="ticketPeriodItem.isShowSelectData === 1">
                      <div class="dateAndTimeBox"
                        v-for="(ticketTimeItem, ticketTimeIndex) in ticketPeriodItem.goods_ticket_datetimes"
                        :key="ticketTimeIndex">
                        <el-form-item :label="ticketTimeIndex === 0 ? '活动时间：' : ' '" class="formItem">
                          <div class="timeBox">
                            <el-select v-if="" v-model="ticketTypeList[ticketTypeIndex]
    .ticketPeriodDates[ticketPeriodIndex]
    .ticketPeriodTimes[ticketTimeIndex]
    .ticketHourLow
    " class="select" filterable @change="
    ticketPeriodTime(
      ticketTypeIndex,
      ticketPeriodIndex,
      ticketTimeIndex
    )
    " :disabled="isUp">
                              <el-option v-for="item in ticketHourList" :key="item" :label="item"
                                :value="item"></el-option>
                            </el-select>
                            <div class="colon">:</div>
                            <el-select v-model="ticketTypeList[ticketTypeIndex]
    .ticketPeriodDates[ticketPeriodIndex]
    .ticketPeriodTimes[ticketTimeIndex]
    .ticketMinLow
    " class="select" filterable @change="
    ticketPeriodTime(
      ticketTypeIndex,
      ticketPeriodIndex,
      ticketTimeIndex
    )
    " :disabled="isUp">
                              <el-option v-for="item in ticketMinList" :key="item" :label="item"
                                :value="item"></el-option>
                            </el-select>
                            <span class="to">至</span>
                            <el-select v-model="ticketTypeList[ticketTypeIndex]
    .ticketPeriodDates[ticketPeriodIndex]
    .ticketPeriodTimes[ticketTimeIndex]
    .ticketHourHigh
    " class="select" filterable @change="
    ticketPeriodTime(
      ticketTypeIndex,
      ticketPeriodIndex,
      ticketTimeIndex
    )
    " :disabled="isUp">
                              <el-option v-for="item in ticketHourList" :key="item" :label="item"
                                :value="item"></el-option>
                            </el-select>
                            <div class="colon">:</div>
                            <el-select v-model="ticketTypeList[ticketTypeIndex]
    .ticketPeriodDates[ticketPeriodIndex]
    .ticketPeriodTimes[ticketTimeIndex]
    .ticketMinHigh
    " class="select" filterable @change="
    ticketPeriodTime(
      ticketTypeIndex,
      ticketPeriodIndex,
      ticketTimeIndex
    )
    " :disabled="isUp">
                              <el-option v-for="item in ticketMinList" :key="item" :label="item"
                                :value="item"></el-option>
                            </el-select>
                          </div>
                        </el-form-item>
                        <div style="display: flex">
                          <el-button type="text" style="color: #3471cd" @click="
    addAndDelticketTime(
      'add',
      ticketTypeIndex,
      ticketPeriodIndex
    )
    " v-if="ticketTimeIndex === ticketPeriodItem.goods_ticket_datetimes.length - 1 &&
    (!isUp)
    ">
                            + 其他时间
                          </el-button>
                          <el-button type="text" style="color: rgba(254, 85, 88, 1)" @click="
    addAndDelticketTime(
      'del',
      ticketTypeIndex,
      ticketPeriodIndex,
      ticketTimeIndex
    )
    " v-if="ticketTimeIndex !== 0 && (!isUp)">
                            删除
                          </el-button>
                        </div>
                      </div>
                    </div>
                    <div class="dateAndTimeBox" v-if="baseInfoForm.sale_limit_sign === 3">
                      <el-form-item label="本期可售数：" class="formItem">
                        <div style="width: 150px; display: flex">
                          <el-input v-model="ticketTypeList[ticketTypeIndex].ticketPeriodDates[ticketPeriodIndex].count"
                            class="inp" @change="
    countChange(ticketTypeIndex, ticketPeriodIndex)
    " :disabled="isUp && !periodEditor.isEditing"></el-input>
                          <span style="margin-left: 10px; color: #999">份</span>
                        </div>
                      </el-form-item>
                    </div>

                    <div class="row no-gutters" style="margin-bottom: -16px" v-if="isUp">
                      <div class="col"></div>
                      <div class="col-auto">
                        <el-button type="text" @click="publishNewDates(ticketPeriodItem)">发布新日期</el-button>
                      </div>
                    </div>
                    <!-- end -->
                  </div>
                </div>

                <div class="ticketTypeBottomBox" v-if="ticketTypeItem.multi_period === 0">
                  <div class="ticketTypeBottom noBorder">
                    <div class="dateAndTimeBox" style="margin-top: 0">
                      <el-form-item label="活动日期：" class="formItem">
                        <el-date-picker style="width: 300px"
                          v-model="ticketTypeList[ticketTypeIndex].ticketPeriodDates[0].dates" type="daterange"
                          align="center" :editable="false" size="small" range-separator="-" start-placeholder="开始日期"
                          end-placeholder="结束日期" value-format="timestamp" :picker-options="datePickerOptions"
                          @change="ticketPeriodData(ticketTypeIndex, 0)"
                          :disabled="isUp && !periodEditor.isEditing"></el-date-picker>
                      </el-form-item>
                    </div>
                    <div class="dayBox">
                      <el-form-item label="可选日期：" class="formItem">
                        <div class="tagBox">
                          <el-tag class="tag"
                            :class="{ act: !!ticketTypeList[ticketTypeIndex].ticketPeriodDates[0].dayList[0] }" @click="
    day(
      ticketTypeIndex,
      0,
      ticketTypeList[ticketTypeIndex]
        .ticketPeriodDates[0].showDayList[0]
    )
    ">
                            周一
                          </el-tag>
                          <el-tag class="tag"
                            :class="{ act: ticketTypeList[ticketTypeIndex].ticketPeriodDates[0].dayList[1] }" @click="
    day(
      ticketTypeIndex,
      1,
      ticketTypeList[ticketTypeIndex]
        .ticketPeriodDates[0].showDayList[1]
    )
    ">
                            周二
                          </el-tag>
                          <el-tag class="tag"
                            :class="{ act: ticketTypeList[ticketTypeIndex].ticketPeriodDates[0].dayList[2] }" @click="
    day(
      ticketTypeIndex,
      2,
      ticketTypeList[ticketTypeIndex]
        .ticketPeriodDates[0].showDayList[2]
    )
    ">
                            周三
                          </el-tag>
                          <el-tag class="tag"
                            :class="{ act: ticketTypeList[ticketTypeIndex].ticketPeriodDates[0].dayList[3] }" @click="
    day(
      ticketTypeIndex,
      3,
      ticketTypeList[ticketTypeIndex]
        .ticketPeriodDates[0].showDayList[3]
    )
    ">
                            周四
                          </el-tag>
                          <el-tag class="tag"
                            :class="{ act: ticketTypeList[ticketTypeIndex].ticketPeriodDates[0].dayList[4] }" @click="
    day(
      ticketTypeIndex,
      4,
      ticketTypeList[ticketTypeIndex]
        .ticketPeriodDates[0].showDayList[4]
    )
    ">
                            周五
                          </el-tag>
                          <el-tag class="tag"
                            :class="{ act: ticketTypeList[ticketTypeIndex].ticketPeriodDates[0].dayList[5] }" @click="
    day(
      ticketTypeIndex,
      5,
      ticketTypeList[ticketTypeIndex]
        .ticketPeriodDates[0].showDayList[5]
    )
    ">
                            周六
                          </el-tag>
                          <el-tag class="tag"
                            :class="{ act: ticketTypeList[ticketTypeIndex].ticketPeriodDates[0].dayList[6] }" @click="
    day(
      ticketTypeIndex,
      6,
      ticketTypeList[ticketTypeIndex]
        .ticketPeriodDates[0].showDayList[6]
    )
    ">
                            周日
                          </el-tag>
                        </div>
                      </el-form-item>
                    </div>
                    <div class="dateAndTimeBox" v-if="baseInfoForm.sale_limit_sign === 3">
                      <el-form-item label="本期可售数：" class="formItem">
                        <div style="width: 150px; display: flex">
                          <el-input v-model="ticketTypeList[ticketTypeIndex]
    .ticketPeriodDates[0].count
    " class="inp" @change="countChange(ticketTypeIndex, 0)" :disabled="isUp && !periodEditor.isEditing"></el-input>
                          <span style="margin-left: 10px; color: #999">份</span>
                        </div>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row no-gutters" style="margin-bottom: -16px" v-if="isUp">
                    <div class="col"></div>
                    <div class="col-auto">
                      <el-button type="text"
                        @click="publishNewDates(ticketTypeList[ticketTypeIndex].ticketPeriodDates[0])">
                        发布新日期
                      </el-button>
                    </div>
                  </div>
                </div>

              </div>

              <el-button type="primary" @click="addAndDelTicketType('add')" v-if="!isUp">
                新增票种
              </el-button>
            </el-form-item>


            <el-form-item label="预定截止时间:">
              <div style="width: 600px" class="endTimeBox">
                <div class="endTimeBox1">
                  <span>活动开始前</span>
                  <div class="inpBox">
                    <el-radio v-model="defaultChecked" label="1">
                      <el-input v-model="endTimes.day" class="endTimeIpt" @change="endTimesChange" :disabled="isUp ? true : defaultChecked !== '1'
    "></el-input>
                    </el-radio>
                    <div style="padding: 0 5px">天</div>
                    <el-radio v-model="defaultChecked" label="2">
                      <el-input v-model="endTimes.hour" class="endTimeIpt" @change="endTimesChange" :disabled="isUp ? true : defaultChecked !== '2'
    "></el-input>
                    </el-radio>
                    <div style="padding: 0 5px">时</div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="step1_title">
            <div style="
                width: 12px;
                height: 12px;
                background: #007f7f;
                border-radius: 50%;
                margin-top: 3px;
              "></div>
            <h4>销售/名额显示</h4>
          </div>
          <div>
            <el-form-item label="页面显示方式：">
              <div style="width: 600px">
                <el-radio v-model="baseInfoForm.show_type" :label="1" :disabled="isUp">已售
                </el-radio>
                <el-radio v-model="baseInfoForm.show_type" :label="2" :disabled="isUp">剩余
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="初始已售数量：" prop="init_sale_num">
              <el-input v-model="baseInfoForm.init_sale_num" class="ipt-default" style="width: 100px"
                :disabled="baseInfoForm.show_type === 2"></el-input>
            </el-form-item>
          </div>
          <div class="step1_title">
            <div style="
                width: 12px;
                height: 12px;
                background: #007f7f;
                border-radius: 50%;
                margin-top: 3px;
              "></div>
            <h4>活动退款设置</h4>
          </div>
          <div>
            <el-form-item label="是否支持退款：" prop="refund_type">
              <div style="width: 600px">
                <el-radio v-model="baseInfoForm.refund_type" :label="0" :disabled="isUp" @change="refundTypeChange">不支持
                </el-radio>
                <el-radio v-model="baseInfoForm.refund_type" :label="1" :disabled="isUp">支持
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="退款金额设置：" v-if="baseInfoForm.refund_type === 1" prop="refund_all_sign">
              <div style="width: 600px">
                <el-radio v-model="baseInfoForm.refund_all_sign" :label="1" :disabled="isUp"
                  @change="refundTypeChange">活动开始前退款全部
                </el-radio>
                <el-radio v-model="baseInfoForm.refund_all_sign" :label="2" :disabled="isUp">自定义条件
                </el-radio>
              </div>
              <div class="refundPriceBox" v-if="baseInfoForm.refund_all_sign === 2">
                <div class="box" v-for="(item, index) in baseInfoForm.diys" :key="index">
                  <div class="left">
                    <div class="text" style="width: 190px">
                      距离活动开始天数，如果满足
                    </div>
                    <el-input v-model="item.time_gte" class="soldCountInp" style="width: 100px"
                      :disabled="isUp"></el-input>
                    <div class="text" style="width: 20px">至</div>
                    <el-input v-model="item.time_lte" class="soldCountInp" style="width: 100px"
                      :disabled="isUp"></el-input>
                    <div class="text" style="width: 60px">天，退款</div>
                    <el-input v-model="item.refund_point" class="soldCountInp" style="width: 100px"
                      :disabled="isUp"></el-input>
                    <div class="text" style="width: 16px">%</div>
                  </div>
                  <el-button type="text" style="color: rgba(254, 85, 88, 1)"
                    v-if="index != baseInfoForm.diys.length - 1 && !isUp" @click="addAndDelDiys('del', index)">
                    删除
                  </el-button>
                  <el-button type="text" style="color: #3471cd" v-if="index === baseInfoForm.diys.length - 1 && !isUp"
                    @click="addAndDelDiys('add', index)">+ 新增条件
                  </el-button>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </div>
      <!--步骤3-->
      <div class="content-goods-publish" v-if="activestep === 2">
        <!--活动详情-->
        <div class="base-info-item">
          <div class="step1_title">
            <div style="
                width: 12px;
                height: 12px;
                background: #007f7f;
                border-radius: 50%;
                margin-top: 3px;
              "></div>
            <h4>活动详情</h4>
          </div>
          <div>
            <el-form-item label="活动内容：" prop="info">
              <div class="activityDetails pr-5">
                <sig-editor-lite v-model="baseInfoForm.info"></sig-editor-lite>
              </div>
            </el-form-item>
          </div>
          <div class="step1_title">
            <div style="
                width: 12px;
                height: 12px;
                background: #007f7f;
                border-radius: 50%;
                margin-top: 3px;
              "></div>
            <h4>活动亮点</h4>
          </div>
          <div>
            <el-form-item label="活动亮点：" prop="lights">
              <div class="characteristicBox" v-for="(item, index) in baseInfoForm.lights" :key="index">
                <el-input v-model="item.light_intro" class="ipt-default" placeholder="请输入活动亮点"></el-input>
                <el-button type="text" style="color: #fe5558" v-if="index != 0" @click="addAndDelLight('del', index)">删除
                </el-button>
              </div>
              <el-button type="primary" @click="addAndDelLight('add')">继续添加
              </el-button>
            </el-form-item>
          </div>
          <div class="step1_title">
            <div style="
                width: 12px;
                height: 12px;
                background: #007f7f;
                border-radius: 50%;
                margin-top: 3px;
              "></div>
            <h4>活动行程</h4>
          </div>
          <div>
            <el-form-item label="活动行程：">
              <div class="tripBox" v-for="(item, index) in baseInfoForm.trips" :key="index">
                <div class="tripContentBox">
                  <div class="left">{{ index + 1 }}</div>
                  <div class="right">
                    <el-input v-model="item.trip_title" class="inp" placeholder="请输入活动行程标题"></el-input>
                    <el-input v-model="item.trip_intro" class="inp" style="margin-top: 10px"
                      placeholder="请输入活动行程内容"></el-input>
                  </div>
                </div>
                <el-button type="text" style="color: #fe5558" v-if="index !== 0"
                  @click="addAndDelTrips('del', index)">删除
                </el-button>
              </div>
              <el-button type="primary" @click="addAndDelTrips('add')">继续添加
              </el-button>
            </el-form-item>
          </div>
          <div class="step1_title">
            <div style="
                width: 12px;
                height: 12px;
                background: #007f7f;
                border-radius: 50%;
                margin-top: 3px;
              "></div>
            <h4>参加须知</h4>
          </div>
          <div>
            <el-form-item label="参加须知：">
              <div class="tripBox" v-for="(item, index) in baseInfoForm.notices" :key="index">
                <div class="tripContentBox">
                  <div class="right">
                    <el-input v-model="item.notice_title" class="inp" :disabled="index === 0 || index === 1"></el-input>
                    <el-input :type="index === 0
    ? 'textarea'
    : index === 1
      ? 'textarea'
      : index === 3
        ? 'textarea'
        : ''
    " :rows="index === 3 ? '2' : ''" v-model="item.notice_intro" class="inp"
                      style="margin-top: 10px; width: 538px; color: #606266"
                      :disabled="index === 0 ? true : index === 1" :placeholder="item.placeholder"></el-input>
                  </div>
                </div>
                <el-button type="text" style="color: #fe5558" v-if="index !== 0 && index !== 1"
                  @click="addAndDelNotices('del', index)">删除
                </el-button>
              </div>
              <el-button type="primary" @click="addAndDelNotices('add')">继续添加</el-button>
            </el-form-item>
          </div>
          <div class="step1_title">
            <div style="
                width: 12px;
                height: 12px;
                background: #007f7f;
                border-radius: 50%;
                margin-top: 3px;
              "></div>
            <h4>精彩回顾</h4>
          </div>
          <div>
            <el-form-item label="精彩回顾：">
              <div class="activityDetails pr-5">
                <sig-editor-lite v-model="baseInfoForm.info2"></sig-editor-lite>
              </div>
            </el-form-item>
          </div>
          <div class="step1_title">
            <div style="
                width: 12px;
                height: 12px;
                background: #007f7f;
                border-radius: 50%;
                margin-top: 3px;
              "></div>
            <h4>案例展示</h4>
          </div>
          <div>
            <el-form-item label="案例展示：">
              <div class="activityDetails pr-5">
                <sig-editor-lite v-model="baseInfoForm.info3"></sig-editor-lite>
              </div>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
    <!--底部-->
    <div class="footer">
      <el-button-group>
        <el-button type="primary" v-if="activestep === 2" @click="previewDetail">活动详情预览
        </el-button>
        <el-button type="primary" @click="POST_activeDraftGoodsActivityAdd">保存草稿
        </el-button>
        <el-button type="primary" @click="pre" v-if="activestep === 1 || activestep === 2">上一步
        </el-button>
        <el-button type="primary" @click="next" v-if="activestep === 0 || activestep === 1"
          :disabled="tickerTimeButton">下一步
        </el-button>
        <el-button type="primary" @click="submit" v-if="activestep === 2">提交
        </el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>
import * as API_activityAddress from "@/api/activityAddress";
import * as API_activityTag from "@/api/activityTag";
import * as API_activityType from "@/api/activityType";
import * as API_activityAdd from "@/api/activityAdd";
import * as API_activityDdraft from "@/api/activityDraft";
import * as API_teacher from "@/api/teacher";
import { mapActions, mapGetters } from "vuex";
import SigEditorLite from "@/components-new/sig-editor-lite/sig-editor-lite.vue";
import AddressSelector from "@/components-new/address-selector/address-selector.vue";
import ImgUploader from "@/components-new/img-uploader/img-uploader.vue";
import { PeriodEditor } from "@/pages/activity/activity-detail/activity-detail";
// import * as API_activityTeacher from "@/api/activityTeacher";

export default {
  components: {
    SigEditorLite,
    AddressSelector,
    ImgUploader,
  },
  data () {
    /** 判断字符串是否是数字 */
    function checkNumber (theObj) {
      if (theObj === null || theObj === "") {
        return true;
      }
      var reg = /^[0-9]+['.']?[0-9]*$/;
      return reg.test(theObj);

    }

    var activity_name = (rule, value, callback) => {
      if (this.baseInfoForm.activity_name === "") {
        return callback(new Error("请输入活动标题"));
      } else if (
        this.baseInfoForm.activity_name.length > 30
        // this.returnStringLength(this.baseInfoForm.activity_name) > 60
      ) {
        return callback(new Error("活动标题需在30个汉字内"));
      } else {
        return callback();
      }
    };
    var activityTypeChoose = (rule, value, callback) => {
      if (this.activityTypeChoose.length === 0) {
        return callback(new Error("请选择活动分类"));
      } else {
        return callback();
      }
    };
    var activityTime = (rule, value, callback) => {
      if (this.activityTime.activityTimeType === "") {
        return callback(new Error("请选择活动时长方式"));
      } else if (
        this.activityTime.activityTimeType === "1" &&
        this.activityTime.activityTimeDay === ""
      ) {
        return callback(new Error("请输入活动天数"));
      } else if (
        (this.activityTime.activityTimeType === "1" &&
          this.activityTime.activityTimeDay < 0.5) ||
        this.activityTime.activityTimeDay > 31
      ) {
        return callback(new Error("活动天数区间0.5-31天"));
      } else if (
        this.activityTime.activityTimeType === "1" &&
        this.activityTime.activityTimeDay % 0.5 !== 0
      ) {
        return callback(new Error("活动天数间隔0.5天"));
      } else if (
        this.activityTime.activityTimeType === "2" &&
        this.activityTime.activityTimeHour === ""
      ) {
        return callback(new Error("请输入活动小时数"));
      } else if (
        (this.activityTime.activityTimeType === "2" &&
          this.activityTime.activityTimeHour < 1) ||
        this.activityTime.activityTimeHour > 23
      ) {
        return callback(new Error("活动小时区间1-23小时"));
      } else {
        return callback();
      }
    };
    var child_limit = (rule, value, callback) => {
      if (this.baseInfoForm.child_limit_sign === "") {
        return callback(new Error("请选择孩子年龄方式"));
      } else if (this.baseInfoForm.child_limit_sign === 2) {
        if (
          this.baseInfoForm.child_limit_gte === "" ||
          this.baseInfoForm.child_limit_lt === ""
        ) {
          return callback(new Error("请选择孩子年龄上限和下限"));
        } else {
          return callback();
        }
      } else {
        return callback();
      }
    };
    var activityAddress = (rule, value, callback) => {
      if (this.addressDetails === "") {
        return callback(new Error("请选择详细地址"));
      } else {
        return callback();
      }
    };
    var service_phone = (rule, value, callback) => {
      if (
        this.baseInfoForm.service_phone1 === "" &&
        this.baseInfoForm.service_phone2 === ""
      ) {
        return callback(new Error("请填写客服电话"));
      } else {
        return callback();
      }
    };
    var activityTagChooseList = (rule, value, callback) => {
      if (this.activityTagChooseList.length === 0) {
        return callback(new Error("请选择活动标签"));
      } else {
        return callback();
      }
    };
    var abilityTagChooseList = (rule, value, callback) => {
      if (this.abilityTagChooseList.length === 0) {
        return callback(new Error("请选择能力标签"));
      } else {
        return callback();
      }
    };
    var traveler_sign = (rule, value, callback) => {
      if (this.baseInfoForm.traveler_sign === "") {
        return callback(new Error("请选择是否填写出行人信息"));
      } else if (this.baseInfoForm.traveler_sign === 1) {
        var chooseLength = 0;
        this.travelerList.forEach((item) => {
          if (item === 1) {
            chooseLength++;
          }
        });
        if (chooseLength === 0) {
          return callback(new Error("至少选择一个出行人信息"));
        } else {
          return callback();
        }
      } else {
        return callback();
      }
    };
    var bannerListVal = (rule, value, callback) => {
      if (!this.bannerList1[0]) {
        return callback(new Error("请上传第1个位置的图片"));
      } else {
        callback();
      }
    };
    var goods_gallery_dos = (rule, value, callback) => {
      if (this.bannerList1.length === 0) {
        return callback(new Error("请上传封面图"));
      }
      return callback();
    };
    var sale_limit_sign = (rule, value, callback) => {
      if (this.activestep === 1 && this.baseInfoForm.sale_limit_sign === "") {
        return callback(new Error("请选择票种数量方式"));
      } else if (
        this.activestep === 1 &&
        this.baseInfoForm.sale_limit_sign === 2 &&
        this.baseInfoForm.sale_limit_num === ""
      ) {
        return callback(new Error("请填写总预定数"));
      } else {
        return callback();
      }
    };
    var ticket_query_vos = (rule, value, callback) => {
      if (this.activestep === 1) {
        this.baseInfoForm.ticket_query_vos.forEach(
          (ticketTypeItem, ticketTypeIndex) => {
            // console.log(ticketTypeItem)
            if (ticketTypeItem.ticket_name === "") {
              return callback(new Error("请填写票种名称"));
            } else if (
              this.returnStringLength(ticketTypeItem.ticket_name) > 60
            ) {
              return callback(new Error("票种名称规定30个汉字"));
            } else if (ticketTypeItem.fee_info === "") {
              return callback(new Error("请填写费用描述"));
            } else if (
              this.returnStringLength(ticketTypeItem.ticket_info) > 60
            ) {
              return callback(new Error("票种描述规定30个汉字"));
            } else if (this.returnStringLength(ticketTypeItem.fee_info) > 200) {
              return callback(new Error("费用描述规定100个汉字"));
            } else if (!checkNumber(ticketTypeItem.mktprice)) {
              return callback(new Error("原价只能输入数字"));
            } else if (ticketTypeItem.price === "") {
              return callback(new Error("请填写销售价"));
            } else if (ticketTypeItem.price < 0) {
              return callback(new Error("销售价不能小于0"));
            } else if (
              String(ticketTypeItem.price).indexOf(".") !== -1 &&
              String(ticketTypeItem.price).split(".")[1].length !== 1
            ) {
              return callback(new Error("销售价仅支持1位小数"));
            } else if (!checkNumber(ticketTypeItem.price)) {
              return callback(new Error("销售价只能输入数字"));
            } else if (ticketTypeItem.limit_sign === "") {
              return callback(new Error("请选择用户限购"));
            } else if (
              ticketTypeItem.limit_sign === 1 &&
              ticketTypeItem.buy_limit_num === ""
            ) {
              return callback(new Error("请输入用户限购数量"));
            } else {
              ticketTypeItem.goods_ticket_date_vos.forEach(
                (ticketPeriodItem, ticketPeriodIndex) => {
                  if (ticketPeriodItem.start_date === "") {
                    if (ticketPeriodItem.isShowSelectData === 1) {
                      return callback(new Error("请选择活动时间"));
                    } else {
                      return callback();
                    }
                  } else if (this.baseInfoForm.sale_limit_sign === 3) {
                    ticketPeriodItem.goods_ticket_datetimes.forEach(
                      (ticketTimeItem, ticketTimeIndex) => {
                        if (ticketTimeItem.tpeople_num === "") {
                          return callback(new Error("请填写本期可售数"));
                        } else {
                          if (
                            ticketPeriodIndex ===
                            ticketTypeItem.goods_ticket_date_vos.length - 1 &&
                            ticketTypeIndex ===
                            this.baseInfoForm.ticket_query_vos.length - 1 &&
                            ticketTimeIndex ===
                            ticketPeriodItem.goods_ticket_datetimes.length - 1
                          ) {
                            return callback();
                          }
                        }
                      }
                    );
                  } else {
                    if (
                      ticketPeriodIndex ===
                      ticketTypeItem.goods_ticket_date_vos.length - 1 &&
                      ticketTypeIndex ===
                      this.baseInfoForm.ticket_query_vos.length - 1
                    ) {
                      return callback();
                    }
                  }
                }
              );
            }
          }
        );
      } else {
        return callback();
      }
    };
    var init_sale_num = (rule, value, callback) => {
      if (this.activestep === 1 && this.baseInfoForm.init_sale_num === "") {
        return callback(new Error("请填写初始已售数量"));
      } else if (this.baseInfoForm.init_sale_num < 0) {
        return callback(new Error("初始已售数量必须大于0"));
      } else {
        return callback();
      }
    };
    var refund_type = (rule, value, callback) => {
      if (this.activestep === 1 && this.baseInfoForm.refund_type === "") {
        return callback(new Error("请选择是否支持退款"));
      } else {
        return callback();
      }
    };
    var refund_all_sign = (rule, value, callback) => {
      if (this.activestep === 1 && this.baseInfoForm.refund_type === 1) {
        if (this.baseInfoForm.refund_all_sign === null) {
          return callback(new Error("请选择退款金额设置"));
        } else {
          if (this.baseInfoForm.refund_all_sign === 2) {
            this.baseInfoForm.diys.forEach((item, index) => {
              if (item.refund_point === null || item.refund_point === "") {
                return callback(new Error("请填写退款比例"));
              } else if (item.time_gte === null || item.time_gte === "") {
                return callback(new Error("请填写距离活动开始天数-大于等于"));
              } else if (item.time_gte === "0" || item.time_lte === "0") {
                return callback(new Error("不允许输入0天"));
              } else if (item.time_lte === null || item.time_lte === "") {
                return callback(new Error("请填写距离活动开始天数-小于等于"));
              } else {
                if (index === this.baseInfoForm.diys.length - 1) {
                  return callback();
                }
              }
            });
          } else {
            return callback();
          }
        }
      } else {
        return callback();
      }
    };
    var info = (rule, value, callback) => {
      if (this.activestep === 2 && this.baseInfoForm.info === "") {
        return callback(new Error("请填写活动内容"));
      } else {
        return callback();
      }
    };
    var lights = (rule, value, callback) => {
      if (this.activestep === 2) {
        this.baseInfoForm.lights.forEach((item, index) => {
          if (item.light_intro === "") {
            return callback(new Error("请填写活动内容"));
          } else {
            if (index === this.baseInfoForm.lights.length - 1) {
              return callback();
            }
          }
        });
      } else {
        return callback();
      }
    };

    const self = this;

    return {
      /**  加载中。。。 */
      loading: false,
      datePickerOptions: {
        disabledDate (time) {
          if (self.periodEditor.isEditing) return false;

          const date = new Date();
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          return time.getTime() < date.getTime();
        },
      },

      /** 上传视频中 */
      videoUpload: false,
      progressColor: "#008080",
      videoProgress: 0,
      /**  当前状态/模式 默认发布商品0 编辑商品1 编辑草稿箱商品2 */
      currentStatus: 0,

      /**  当前激活步骤 */
      activestep: 0,
      /** 产品轮播图 */
      // bannerList: [],
      bannerList1: [],
      bannerList2: [],
      /** 广告位图 */
      advertList: [],
      /**  基本信息提交表单 */
      baseInfoForm: {
        activity_name: "",

        category_first: "",
        category_id: "",
        category_second: "",
        category_id2: "",

        activity_duration: "",
        // 活动新添加时间
        duration_day: "",
        duration_hour: "",
        child_limit_sign: "",
        child_limit_gte: "",
        child_limit_lt: "",

        partake: "",

        addr_type: "",

        province_id: "",
        city_id: "",
        country_id: "",
        addr_detail: "",
        province: "",
        city: "",
        country: "",

        service_phone1: "",
        service_phone2: "",

        worktime_sign: "",

        label: "",

        label2: "",

        coupon_sign: "",

        traveler_sign: "",
        traveler_write_limit: "",

        emergency_contact_sign: "",

        goods_gallery_dos: [],

        video: "",

        teacher_ids: null,

        sale_limit_sign: "",
        quantity: "",
        ticket_query_vos: [
          {
            ticket_name: "",
            ticket_info: "",
            fee_info: "",
            mktprice: "0",
            price: "",
            limit_sign: 0,
            buy_limit_num: "",
            multi_period: 0,
            goods_ticket_date_vos: [
              {
                ticket_num: 0,
                start_date: "",
                end_date: "",
                sale_num: "",
                isShowSelectData: 1,
                goods_ticket_datetimes: [
                  {
                    time_start: "00:00",
                    time_end: "00:00",
                    tpeople_num: "",
                    tpeople_use_num: "",
                  },
                ],
                ticket_weekly: "",
              },
            ],
          },
        ],
        mkt_low: "",
        mkt_high: "",
        price_low: "",
        price_top: "",
        buy_limit_sign: "",
        buy_limit_day: "0",
        buy_limit_hour: "0",
        buy_limit_datetime: "",
        init_sale_num: "0",
        show_type: "0",
        refund_type: "",
        refund_all_sign: "",
        diys: [
          {
            refund_point: "",
            time_gte: "",
            time_lte: "",
          },
        ],

        info: "",
        info2: "",//精彩回顾
        info3: "",//案例展示
        lights: [
          {
            light_intro: "",
          },
        ],
        trips: [
          {
            trip_title: "",
            trip_intro: "",
          },
        ],
        notices: [
          {
            notice_title: "退款规则",
            notice_intro: "",
            placeholder: "",
          },
          {
            notice_title: "预定截止时间",
            notice_intro: "",
            placeholder: "",
          },
          {
            notice_title: "领票方式",
            notice_intro: "",
            placeholder: "如短信验证码、二维码、纸质票、电子票、无等多种方式",
          },
          {
            notice_title: "入场须知",
            notice_intro: "",
            placeholder: "",
          },
          {
            notice_title: "费用包含",
            notice_intro: "",
            placeholder: "",
          },
          {
            notice_title: "是否有保险",
            notice_intro: "",
            placeholder: "",
          },
          {
            notice_title: "特别提醒",
            notice_intro: "",
            placeholder: "",
          },
          {
            notice_title: "其他须知",
            notice_intro: "",
            placeholder: "",
          },
          {
            notice_title: "需自备物品",
            notice_intro: "",
            placeholder: "",
          },
        ],
      },

      /**  基本信息提交表单验证 */
      baseInfoFormRules: {
        // 第一部分
        activity_name: [
          {
            required: true,
            validator: activity_name,
            trigger: "blur",
          },
        ],
        activityTypeChoose: [
          {
            required: true,
            validator: activityTypeChoose,
            trigger: "blur",
          },
        ],
        activityTime: [
          {
            required: true,
            validator: activityTime,
            trigger: "blur",
          },
        ],
        child_limit: [
          {
            required: true,
            validator: child_limit,
            trigger: "blur",
          },
        ],
        partake: [
          {
            required: true,
            message: "请选择参与方式",
            trigger: "blur",
          },
        ],
        addr_type: [
          {
            required: true,
            message: "请选择地址类型",
            trigger: "blur",
          },
        ],
        activityAddress: [
          {
            required: true,
            validator: activityAddress,
            trigger: "blur",
          },
        ],
        service_phone: [
          {
            required: true,
            validator: service_phone,
            trigger: "blur",
          },
        ],
        activityTagChooseList: [
          {
            required: true,
            validator: activityTagChooseList,
            trigger: "blur",
          },
        ],
        abilityTagChooseList: [
          {
            required: true,
            validator: abilityTagChooseList,
            trigger: "blur",
          },
        ],
        coupon_sign: [
          {
            required: true,
            message: "请选择是否使用优惠券",
            trigger: "blur",
          },
        ],
        traveler_sign: [
          {
            required: true,
            validator: traveler_sign,
            trigger: "blur",
          },
        ],
        emergency_contact_sign: [
          {
            required: true,
            message: "请选择是否填写进行联系人",
            trigger: "blur",
          },
        ],
        bannerListVal: [
          {
            required: true,
            validator: bannerListVal,
            trigger: "blur",
          },
        ],
        goods_gallery_dos: [
          {
            required: true,
            validator: goods_gallery_dos,
            trigger: "blur",
          },
        ],
        // 第二部分
        sale_limit_sign: [
          {
            required: true,
            validator: sale_limit_sign,
            trigger: "blur",
          },
        ],
        ticket_query_vos: [
          {
            required: true,
            validator: ticket_query_vos,
            trigger: "blur",
          },
        ],
        init_sale_num: [
          {
            required: true,
            validator: init_sale_num,
            trigger: "blur",
          },
        ],
        refund_type: [
          {
            required: true,
            validator: refund_type,
            trigger: "blur",
          },
        ],
        refund_all_sign: [
          {
            required: true,
            validator: refund_all_sign,
            trigger: "blur",
          },
        ],
        // 第三部分
        info: [
          {
            required: true,
            validator: info,
            trigger: "blur",
          },
        ],
        lights: [
          {
            required: true,
            validator: lights,
            trigger: "blur",
          },
        ],
      },

      address2: [],
      /**  新建活动地址信息 */
      addressNewParams: {
        province_id: "",
        city_id: "",
        country_id: "",
        remarks: "",
        addr_detail: "",
      },

      /**  选中活动地址信息 */
      addressDetails: "",

      /**  活动分类列表 */
      activityTypeList: [],
      activityTypeListOriginal: [],

      /**  选中活动分类 */
      activityTypeChoose: [],

      /** 地址列表 */
      activityAddressList: [],

      /**  是否新建地址 */
      isNewAddress: false,

      // 选中的地区id
      activityAddress: "",
      address1: "",

      /**  活动标签列表 */
      activityTagList: [],
      activityTagChooseList: [],
      activityNewTag: "",
      activityTagPlatformList: [],

      /**  是否新建活动标签 */
      activityTagShow: false,

      /** 能力标签列表 */
      abilityTagList: [],
      abilityTagChooseList: [],
      abilityNewTag: "",
      abilityTagPlatformList: [],

      /** 是否新建能力标签 */
      abilityTagShow: false,

      /** 出行人信息列表 */
      travelerList: [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],

      /** 导师列表 */
      activityTutorList: [],

      /** 选中导师列表 */
      teacherChooseList: [],

      /** 用户限购方式 */
      quotaTypeList: [
        {
          id: 0,
          name: "不限购",
        },
        {
          id: 1,
          name: "限购",
        },
      ],

      /** 期次类型 */
      periodTypeList: [
        {
          id: 0,
          name: "不多期",
        },
        {
          id: 1,
          name: "多期",
        },
      ],

      /** 活动日期和时间列表 */
      ticketTypeList: [
        {
          ticketPeriodDates: [
            {
              dates: [],
              ticketPeriodTimes: [
                {
                  ticketHourLow: "00",
                  ticketMinLow: "00",
                  ticketHourHigh: "00",
                  ticketMinHigh: "00",
                },
              ],
              dayList: [false, false, false, false, false, false, false],
              showDayList: [true, true, true, true, true, true, true],
              sale_num: "",
            },
          ],
        },
      ],

      /** 活动时间小时列表 */
      ticketHourList: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],

      /** 活动时间限制按钮点击 */
      tickerTimeButton: false,

      /** 活动时间分钟列表 */
      ticketMinList: ["00", "15", "30", "45"],

      /** 预定截止时间分类 */
      endTimeList: [
        {
          id: "2",
          name: "活动开始前",
        },
        {
          id: "3",
          name: "活动结束前",
        },
      ],

      /** 预定截止时间选中分类 */
      endTimeType: "",
      endTime: "2",

      /** 预定截止时间的天数和小时数 */
      endTimes: {
        day: "0",
        hour: "0",
      },

      /** 默认选中 天 */
      defaultChecked: "1",

      /** 预定截止时间的日期 */
      buy_limit_datetime: "",

      /** 活动时长数组 */
      activityTime: {
        activityTimeType: "",
        activityTimeDay: "",
        activityTimeHour: "",
      },

      /** 是否为非上架商品编辑页面 */
      isEdit: false,

      /** 是否为上架商品编辑页面 */
      isUp: false,

      /** 保存草稿是否成功开关 */
      activeDraftGoodsActivityAddIsOk: true,

      /** 提交活动是否成功开关 */
      activeGoodsActivityAddIsOk: true,

      /** 是否展示图片说明 */
      imgExplain: false,

      periodEditor: new PeriodEditor(() => this),
    };
  },
  computed: {
    ...mapGetters(["shopInfo"]),
  },
  mounted () {
    // const editor = new E('#wangedit')
    // editor.config.height = 500
    // editor.create()
    this.baseInfoForm.service_phone1 = this.shopInfo.phone1;
    this.baseInfoForm.service_phone2 = this.shopInfo.phone2;
    // 查询地址列表
    this.POST_activeAddressShopList();

    // 查询平台标签列表
    this.POST_activeLabelList(1);
    this.POST_activeLabelList(2);

    // 历史标签
    this.POST_shopLabelList(1);
    this.POST_shopLabelList(2);

    // 查询活动分类列表 */
    this.GET_activeTypeList();

    // 查询导师列表 */
    this.POST_activeTeacherShopList();

    // 编辑页面回显
    if (this.$route.params.type === "edit") {
      const id = this.$route.params.id;
      this.isEdit = true;
      this.GET_activeGoodsActivityShow(id);
    } else if (this.$route.params.type === "editUp") {
      const id = this.$route.params.id;
      this.isEdit = true;
      this.isUp = true;
      this.GET_activeGoodsActivityShow(id);
    } else if (this.$route.params.type === "draft") {
      const id = this.$route.params.id;
      this.GET_activeDraftGoodsActivityShow(id);
    } else if (this.$route.params.type === "newCopy") {
      const id = this.$route.params.id;
      this.GET_activeGoodsActivityShow(id);
    }

    // console调试使用
    // window.vue = this.baseInfoForm;
  },
  activated () {
    // 查询地址列表
    this.POST_activeAddressShopList();

    // 查询标签列表
    this.POST_activeLabelList(1);
    this.POST_activeLabelList(2);

    // 查询活动分类列表 */
    this.GET_activeTypeList();

    // 查询导师列表 */
    this.POST_activeTeacherShopList();

    // 编辑页面回显
    if (this.$route.params.type === "edit") {
      const id = this.$route.params.id;
      this.isEdit = true;
      this.GET_activeGoodsActivityShow(id);
    } else if (this.$route.params.type === "editUp") {
      const id = this.$route.params.id;
      this.isEdit = true;
      this.isUp = true;
      this.GET_activeGoodsActivityShow(id);
    } else if (this.$route.params.type === "draft") {
      const id = this.$route.params.id;
      this.GET_activeDraftGoodsActivityShow(id);
    } else if (this.$route.params.type === "newCopy") {
      const id = this.$route.params.id;
      this.GET_activeGoodsActivityShow(id);
    }
  },
  methods: {
    /**
     * 发布新的活动日期
     */
    publishNewDates (period) {
      API_activityAdd.editTicketDate(period).then(resp => {
        this.$message.success('新的活动日期已发布');
      }, err => {
        console.log(err);
      });
    },
    /** 活动详情预览 */
    previewDetail () {
      this.$router.push({
        name: "活动详情预览",
        params: this.baseInfoForm,
      });
    },
    /** 活动时长输入有效性 */
    checkValid (type) {
      if (type === 0) {
        if (
          parseInt(this.activityTime.activityTimeDay) > 0.5 &&
          parseInt(this.activityTime.activityTimeDay) < 31
        ) {
          if (this.activityTime.activityTimeDay % 0.5 === 0) {
            // console.log(111);
          } else {
            this.$message.error("请输入0.5天的倍数");
          }
        } else {
          this.$message.error("请输入天数大于0.5天小于31天");
        }
        // console.log(this.activityTime.activityTimeDay);
      }
    },
    /**  上一步 */
    pre () {
      document.getElementsByClassName("root-router-outlet")[0].scrollTop = 0;
      this.loading = false;
      if (this.activestep > 0) {
        this.activestep--;
      }
    },

    /**  下一步 */
    next () {
      this.loading = false;
      if (this.activestep === 0) {
        this.activityTimeChange();
        this.$refs.baseInfoForm.validate((valid) => {
          if (valid) {
            if (this.activestep < 2) {
              this.activestep = this.activestep + 1;
              document.getElementsByClassName(
                "root-router-outlet"
              )[0].scrollTop = 0;
            }
          } else {
            this.$message.error("请把表单必填项填写完整！");
            return false;
          }
        });
        // console.log(this.baseInfoForm);
      } else if (this.activestep === 1) {
        if (this.baseInfoForm.refund_type === 0) {
          this.baseInfoForm.notices[0].notice_intro = "不支持退款";
        } else {
          if (this.baseInfoForm.refund_all_sign === 1) {
            this.baseInfoForm.notices[0].notice_intro = "活动开始前退款全部";
          } else {
            var str = "";
            this.baseInfoForm.diys.forEach((item) => {
              str +=
                "距离活动开始" +
                item.time_gte +
                "至" +
                item.time_lte +
                "天,退款" +
                item.refund_point +
                "%;";
            });
            this.baseInfoForm.notices[0].notice_intro = str;
          }
        }
        if (this.defaultChecked === '1') {
          this.baseInfoForm.notices[1].notice_intro = "活动开始前" + this.endTimes.day + '天';
        } else {
          this.baseInfoForm.notices[1].notice_intro = '活动开始前' + this.endTimes.hour + '小时'
        }
        this.ticketTypeList.forEach((item, index) => {
          this.baseInfoForm.ticket_query_vos[index].goods_ticket_date_vos[0].goods_ticket_datetimes[0].tpeople_use_num = item.ticketPeriodDates[0].count
        })
        // console.log(this.baseInfoForm)
        this.$refs.baseInfoForm.validate((valid) => {
          if (valid) {
            if (this.activestep < 2) {
              this.activestep = this.activestep + 1;
              document.getElementsByClassName(
                "root-router-outlet"
              )[0].scrollTop = 0;
            }
          } else {
            this.$message.error("请把表单必填项填写完整！");
            return false;
          }
        });
      }
    },

    /**  提交 */
    submit () {
      this.$refs.baseInfoForm.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            this.POST_activeGoodsActivityEdit();
          } else {
            this.POST_activeGoodsActivityAdd();
          }
        } else {
          this.$message.error("请把表单必填项填写完整！");
          return false;
        }
      });
    },

    /**  活动编辑回显 */
    GET_activeGoodsActivityShow (id) {
      API_activityAdd.activeGoodsActivityShow(id).then((res) => {
        // console.log(res);
        this.baseInfoForm = res;
        // 产品，广告图片
        this.baseInfoForm.goods_gallery_dos.forEach((item, index) => {
          if (!item.original) return;
          item.url = item.original;

          if (index === 0) {
            this.bannerList1 = [item];
          } else if (index < 5) {
            this.bannerList2.push(item);
          } else if (index < 8) {
            this.advertList.push(item);
          }
        });
        // 回显活动分类
        var valueArr = [];
        this.activityTypeList.forEach((item1) => {
          if (item1.label === this.baseInfoForm.category_first) {
            if (valueArr.length < 2) {
              this.baseInfoForm.category_id = item1.value;
              valueArr.push(item1.value);
            }
            if (this.baseInfoForm.category_second) {
              item1.children.forEach((item2) => {
                if (item2.label === this.baseInfoForm.category_second) {
                  if (valueArr.length < 2) {
                    this.baseInfoForm.category_id2 = item2.value;
                    valueArr.push(item2.value);
                  }
                }
              });
            }
          }
        });
        this.activityTypeChoose = valueArr;

        // 回显活动时长
        if (this.baseInfoForm.duration_day) {
          this.activityTime.activityTimeType = "1";
          this.activityTime.activityTimeDay = this.baseInfoForm.duration_day;
        } else {
          this.activityTime.activityTimeType = "2";
          this.activityTime.activityTimeHour = this.baseInfoForm.duration_hour;
        }

        // 回显选中地址
        this.activityAddressList.forEach((item) => {
          if (
            item.province_id === this.baseInfoForm.province_id &&
            item.city_id === this.baseInfoForm.city_id &&
            item.country_id === this.baseInfoForm.country_id &&
            item.addr_detail === this.baseInfoForm.addr_detail
          ) {
            this.activityAddress = item.id;
            this.addressDetails = [
              item.province,
              item.city,
              item.country,
              item.addr_detail,
            ]
              .filter((s) => !!s)
              .join("");
          }
        });

        // 回显活动标签
        this.activityTagChooseList = this.baseInfoForm.label3;
        this.baseInfoForm.label = "";
        this.activityTagChooseList.forEach((item, index) => {
          if (index === 0) {
            this.baseInfoForm.label += item.id;
          } else {
            this.baseInfoForm.label += "," + item.id;
          }
        });

        // 回显能力标签
        this.abilityTagChooseList = this.baseInfoForm.label4;
        this.baseInfoForm.label2 = "";
        this.abilityTagChooseList.forEach((item, index) => {
          if (index === 0) {
            this.baseInfoForm.label2 += item.id;
          } else {
            this.baseInfoForm.label2 += "," + item.id;
          }
        });

        // 回显出行人信息
        this.travelerList = [];
        if (this.baseInfoForm.traveler_write_limit === "") {
          this.travelerList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        } else {
          this.baseInfoForm.traveler_write_limit.split("").forEach((item) => {
            this.travelerList.push(Number(item));
          });
        }

        // 回显预定截止时间
        if (
          this.baseInfoForm.buy_limit_day ||
          this.baseInfoForm.buy_limit_day === 0
        ) {
          this.defaultChecked = "1";
          this.endTimes.day = this.baseInfoForm.buy_limit_day;
        } else {
          this.defaultChecked = "2";
          this.endTimes.hour = this.baseInfoForm.buy_limit_hour;
        }

        // 回显活动详情
        this.baseInfoForm.info = this.baseInfoForm.intro;
        this.baseInfoForm.info2 = this.baseInfoForm.intro2;
        this.baseInfoForm.info3 = this.baseInfoForm.intro3;

        // 回显票种
        this.baseInfoForm.ticket_query_vos = this.baseInfoForm.ticket_vos;
        this.ticketTypeList = [];
        this.baseInfoForm.ticket_query_vos.forEach(
          (ticketTypeItem, ticketTypeIndex) => {
            var ticketTypeJson = {};
            ticketTypeJson.ticketPeriodDates = [];
            ticketTypeItem.goods_ticket_date_vos.forEach(
              (ticketDateItem, ticketDateIndex) => {
                ticketDateItem.goods_ticket_datetimes.forEach((item11) => {
                  // item11.tpeople_num = 0;
                  // item11.tpeople_use_num = 0;
                });
                var ticketDatesJson = {};
                if (ticketDateItem.start_date === ticketDateItem.end_date) {
                  this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos[ticketDateIndex].isShowSelectData = 1
                } else {
                  this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos[ticketDateIndex].isShowSelectData = 0
                }
                ticketDatesJson.dates = [
                  ticketDateItem.start_date * 1000,
                  ticketDateItem.end_date * 1000,
                ];
                ticketDatesJson.count = ticketDateItem.goods_ticket_datetimes[0].tpeople_num;
                if (ticketDateItem.ticket_num === 0) {
                  var weekArr = ticketDateItem.ticket_weekly.split("|");
                  ticketDatesJson.dayList = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                  ];
                  ticketDatesJson.dayList.forEach(
                    (dayListItem, dayListindex) => {
                      weekArr.forEach((weekArritem) => {
                        if (dayListindex === Number(weekArritem) - 1) {
                          ticketDatesJson.dayList[dayListindex] = true;
                        }
                      });
                    }
                  );
                  if (
                    ticketDateItem.end_date * 1000 -
                    ticketDateItem.start_date * 1000 <
                    7 * 24 * 60 * 60 * 1000
                  ) {
                    var showDayList = [
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                    ];
                    for (var i = 0; i < 7; i++) {
                      if (
                        ticketDateItem.start_date * 1000 +
                        24 * 60 * 60 * 1000 * i <=
                        ticketDateItem.end_date * 1000
                      ) {
                        var getDay = new Date(
                          ticketDateItem.start_date * 1000 +
                          24 * 60 * 60 * 1000 * i
                        ).getDay();
                        if (getDay === 0) {
                          showDayList[6] = true;
                        }
                        if (getDay === 1) {
                          showDayList[0] = true;
                        }
                        if (getDay === 2) {
                          showDayList[1] = true;
                        }
                        if (getDay === 3) {
                          showDayList[2] = true;
                        }
                        if (getDay === 4) {
                          showDayList[3] = true;
                        }
                        if (getDay === 5) {
                          showDayList[4] = true;
                        }
                        if (getDay === 6) {
                          showDayList[5] = true;
                        }
                      }
                    }
                    ticketDatesJson.showDayList = showDayList;
                  } else {
                    ticketDatesJson.showDayList = [
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                    ];
                  }
                }

                (() => {
                  if (!ticketDateItem.goods_ticket_datetimes) return;
                  ticketDatesJson.ticketPeriodTimes = [];
                  ticketDateItem.goods_ticket_datetimes.forEach(
                    (ticketTimeItem, ticketTimeIndex) => {
                      var ticketTimeJson = {};
                      ticketTimeJson.ticketHourLow = ticketTimeItem.time_start.split(
                        ":"
                      )[0];
                      ticketTimeJson.ticketMinLow = ticketTimeItem.time_start.split(
                        ":"
                      )[1];
                      ticketTimeJson.ticketHourHigh = ticketTimeItem.time_end.split(
                        ":"
                      )[0];
                      ticketTimeJson.ticketMinHigh = ticketTimeItem.time_end.split(
                        ":"
                      )[1];
                      ticketDatesJson.ticketPeriodTimes.push(ticketTimeJson);
                    }
                  );
                })()

                ticketTypeJson.ticketPeriodDates.push(ticketDatesJson);
              }
            );
            this.ticketTypeList.push(ticketTypeJson);
          }
        );
        // console.log(this.baseInfoForm.ticket_query_vos)
        // 导师回显
        this.baseInfoForm.teacher_ids = null;
        // console.log(this.baseInfoForm.teachers);
        var teacher_ids = "";
        this.activityTutorList.forEach((item) => {
          this.baseInfoForm.teachers.forEach((item2) => {
            if (item.id === item2.id) {
              if (teacher_ids === "") {
                teacher_ids += item.id;
              } else {
                teacher_ids += "," + item.id;
              }
              this.teacherChooseList.push(item.id);
            }
          });
        });
        if (teacher_ids !== "") {
          this.$set(this.baseInfoForm, "teacher_ids", teacher_ids);
        }
      });
    },

    /**  草稿编辑回显 */
    GET_activeDraftGoodsActivityShow (id) {
      API_activityDdraft.activeDraftGoodsActivityShow(id).then((res) => {
        // console.log(res);
        this.baseInfoForm = res;
        // 回显活动分类
        var valueArr = [];
        this.activityTypeList.forEach((item1) => {
          if (item1.label === this.baseInfoForm.category_first) {
            if (valueArr.length < 2) {
              this.baseInfoForm.category_id = item1.value;
              valueArr.push(item1.value);
            }
            if (this.baseInfoForm.category_second) {
              item1.children.forEach((item2) => {
                if (item2.label === this.baseInfoForm.category_second) {
                  if (valueArr.length < 2) {
                    this.baseInfoForm.category_id2 = item2.value;
                    valueArr.push(item2.value);
                  }
                }
              });
            }
          }
        });
        this.activityTypeChoose = valueArr;

        // 回显活动时长
        if (this.baseInfoForm.duration_day) {
          this.activityTime.activityTimeType = "1";
          this.activityTime.activityTimeDay = this.baseInfoForm.duration_day;
        } else {
          this.activityTime.activityTimeType = "2";
          this.activityTime.activityTimeHour = this.baseInfoForm.duration_hour;
        }

        // 回显选中地址
        this.activityAddressList.forEach((item) => {
          if (
            item.province_id === this.baseInfoForm.province_id &&
            item.city_id === this.baseInfoForm.city_id &&
            item.country_id === this.baseInfoForm.country_id &&
            item.addr_detail === this.baseInfoForm.addr_detail
          ) {
            this.activityAddress = item.id;
            this.addressDetails = [
              item.province,
              item.city,
              item.country,
              item.addr_detail,
            ]
              .filter((s) => !!s)
              .join("");
          }
        });

        // 回显活动标签
        this.activityTagChooseList = this.baseInfoForm.label3;
        this.baseInfoForm.label = "";
        this.activityTagChooseList.forEach((item, index) => {
          if (index === 0) {
            this.baseInfoForm.label += item.id;
          } else {
            this.baseInfoForm.label += "," + item.id;
          }
        });

        // 回显能力标签
        this.abilityTagChooseList = this.baseInfoForm.label4;
        this.baseInfoForm.label2 = "";
        this.abilityTagChooseList.forEach((item, index) => {
          if (index === 0) {
            this.baseInfoForm.label2 += item.id;
          } else {
            this.baseInfoForm.label2 += "," + item.id;
          }
        });

        // 回显出行人信息
        this.travelerList = [];
        if (this.baseInfoForm.traveler_write_limit === "") {
          this.travelerList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        } else {
          this.baseInfoForm.traveler_write_limit.split("").forEach((item) => {
            this.travelerList.push(Number(item));
          });
        }

        // 回显预定截止时间
        if (
          this.baseInfoForm.buy_limit_day ||
          this.baseInfoForm.buy_limit_day === 0
        ) {
          this.defaultChecked = "1";
          this.endTimes.day = this.baseInfoForm.buy_limit_day;
        } else {
          this.defaultChecked = "2";
          this.endTimes.hour = this.baseInfoForm.buy_limit_hour;
        }

        // 回显活动详情
        this.baseInfoForm.info = this.baseInfoForm.intro;

        // 回显票种
        this.baseInfoForm.ticket_vos = JSON.stringify(
          this.baseInfoForm.ticket_vos
        );
        this.baseInfoForm.ticket_vos = this.baseInfoForm.ticket_vos.replace(
          /ticket_date_vos/g,
          "goods_ticket_date_vos"
        );
        this.baseInfoForm.ticket_vos = this.baseInfoForm.ticket_vos.replace(
          /ticket_datetimes/g,
          "goods_ticket_datetimes"
        );
        this.baseInfoForm.ticket_vos = JSON.parse(this.baseInfoForm.ticket_vos);
        this.baseInfoForm.ticket_query_vos = this.baseInfoForm.ticket_vos;
        // console.log(this.baseInfoForm);
        this.ticketTypeList = [];
        this.baseInfoForm.ticket_query_vos.forEach(
          (ticketTypeItem, ticketTypeIndex) => {
            var ticketTypeJson = {};
            ticketTypeJson.ticketPeriodDates = [];
            if (ticketTypeItem.goods_ticket_date_vos.length !== 0) {
              ticketTypeItem.goods_ticket_date_vos.forEach(
                (ticketDateItem, ticketDateIndex) => {
                  ticketDateItem.goods_ticket_datetimes.forEach((item11) => {
                    item11.tpeople_use_num = 0;
                  });
                  var ticketDatesJson = {};
                  if (ticketDateItem.start_date === ticketDateItem.end_date) {
                    this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos[ticketDateIndex].isShowSelectData = 1
                  } else {
                    this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos[ticketDateIndex].isShowSelectData = 0
                  }
                  ticketDatesJson.dates = [ticketDateItem.start_date * 1000, ticketDateItem.end_date * 1000];
                  ticketDatesJson.count =
                    ticketDateItem.goods_ticket_datetimes[0].tpeople_num;
                  if (ticketDateItem.ticket_num === 0) {
                    var weekArr = ticketDateItem.ticket_weekly.split("|");
                    ticketDatesJson.dayList = [
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                    ];
                    ticketDatesJson.dayList.forEach(
                      (dayListItem, dayListindex) => {
                        weekArr.forEach((weekArritem) => {
                          if (dayListindex === Number(weekArritem) - 1) {
                            ticketDatesJson.dayList[dayListindex] = true;
                          }
                        });
                      }
                    );
                    if (
                      ticketDateItem.end_date * 1000 -
                      ticketDateItem.start_date * 1000 <
                      7 * 24 * 60 * 60 * 1000
                    ) {
                      var showDayList = [
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                      ];
                      for (var i = 0; i < 7; i++) {
                        if (
                          ticketDateItem.start_date * 1000 +
                          24 * 60 * 60 * 1000 * i <=
                          ticketDateItem.end_date * 1000
                        ) {
                          var getDay = new Date(
                            ticketDateItem.start_date * 1000 +
                            24 * 60 * 60 * 1000 * i
                          ).getDay();
                          if (getDay === 0) {
                            showDayList[6] = true;
                          }
                          if (getDay === 1) {
                            showDayList[0] = true;
                          }
                          if (getDay === 2) {
                            showDayList[1] = true;
                          }
                          if (getDay === 3) {
                            showDayList[2] = true;
                          }
                          if (getDay === 4) {
                            showDayList[3] = true;
                          }
                          if (getDay === 5) {
                            showDayList[4] = true;
                          }
                          if (getDay === 6) {
                            showDayList[5] = true;
                          }
                        }
                      }
                      ticketDatesJson.showDayList = showDayList;
                    } else {
                      ticketDatesJson.showDayList = [
                        true,
                        true,
                        true,
                        true,
                        true,
                        true,
                        true,
                      ];
                    }
                  } else {
                    // 2021-3-4 挪到了下面，为了解决页面报错的bug
                  }

                  (() => {
                    if (!ticketDateItem.goods_ticket_datetimes) return;
                    ticketDatesJson.ticketPeriodTimes = [];
                    ticketDateItem.goods_ticket_datetimes.forEach((ticketTimeItem, ticketTimeIndex) => {
                      var ticketTimeJson = {};
                      ticketTimeJson.ticketHourLow = ticketTimeItem.time_start.split(
                        ":"
                      )[0];
                      ticketTimeJson.ticketMinLow = ticketTimeItem.time_start.split(
                        ":"
                      )[1];
                      ticketTimeJson.ticketHourHigh = ticketTimeItem.time_end.split(
                        ":"
                      )[0];
                      ticketTimeJson.ticketMinHigh = ticketTimeItem.time_end.split(
                        ":"
                      )[1];
                      ticketDatesJson.ticketPeriodTimes.push(ticketTimeJson);
                    }
                    );
                  })();

                  ticketTypeJson.ticketPeriodDates.push(ticketDatesJson);
                }
              );
            }
            this.ticketTypeList.push(ticketTypeJson);
            // console.log("--------------------------");
            // console.log(this.ticketTypeList);
          }
        );

        // 回显活动上传图片
        this.baseInfoForm.goods_gallerys.forEach((item, index) => {
          if (!item.original) return;
          item.url = item.original;

          if (index === 0) {
            this.bannerList1 = [item];
          } else if (index < 5) {
            this.bannerList2.push(item);
          } else if (index < 8) {
            this.advertList.push(item);
          }
        });

        // 导师回显
        this.baseInfoForm.teacher_ids = null;
        // console.log("------------------------");
        // console.log(this.baseInfoForm.teachers);
        var teacher_ids = "";
        this.activityTutorList.forEach((item) => {
          if (this.baseInfoForm.teachers) {
            this.baseInfoForm.teachers.forEach((item2) => {
              if (item.id === item2.id) {
                if (teacher_ids === "") {
                  teacher_ids += item.id;
                } else {
                  teacher_ids += "," + item.id;
                }
                this.teacherChooseList.push(item.id);
              }
            });
          }
        });
        if (teacher_ids !== "") {
          this.$set(this.baseInfoForm, "teacher_ids", teacher_ids);
        }
      });
    },

    mixGallery () {
      const goods_gallery_dos = new Array(8)
        .fill({
          sort: 0,
          original: "",
        })
        .map((item, index) => {
          item = JSON.parse(JSON.stringify(item));
          item.sort = index;
          return item;
        });

      goods_gallery_dos[0].original = this.bannerList1[0].url;
      [1, 2, 3, 4].forEach((index) => {
        const item = this.bannerList2[index - 1];
        if (item) {
          goods_gallery_dos[index].original = item.url;
        } else {
          goods_gallery_dos[index].original = "";
        }
      });
      [5, 6, 7].forEach((index) => {
        const item = this.advertList[index - 5];
        if (item) {
          goods_gallery_dos[index].original = item.url;
        } else {
          goods_gallery_dos[index].original = "";
        }
      });
      this.baseInfoForm.goods_gallery_dos = goods_gallery_dos;
    },

    /**  提交添加活动 */
    POST_activeGoodsActivityAdd () {
      if (this.activeGoodsActivityAddIsOk === true) {
        this.activeGoodsActivityAddIsOk = false;
        this.mixGallery();
        this.baseInfoForm.ticket_query_vos.forEach((item) => {
          if (item.multi_period === 1) {
            item.goods_ticket_date_vos.forEach((i) => {
              i.ticket_weekly = "";
            });
          }
        });
        console.log(this.baseInfoForm.ticket_query_vos)
        API_activityAdd.activeGoodsActivityAdd(this.baseInfoForm).then((res) => {
          // console.log(res);
          this.$message.success("提交成功");
          this.$router.push({
            name: "活动列表",
          });
        }).catch((res) => {
          this.activeGoodsActivityAddIsOk = true;
        });
      }
    },

    /**  提交编辑活动 */
    POST_activeGoodsActivityEdit () {
      this.mixGallery();
      this.baseInfoForm.ticket_query_vos.forEach((item) => {
        if (item.multi_period === 1) {
          item.goods_ticket_date_vos.forEach((i) => {
            i.ticket_weekly = "";
          });
        }
      });
      console.log(this.baseInfoForm.ticket_query_vos)
      API_activityAdd.activeGoodsActivityEdit(this.baseInfoForm).then((res) => {
        // console.log(res);
        this.$message.success("提交成功");
        this.$router.push({
          name: "活动列表",
        });
      });
    },

    /**  草稿添加活动 */
    POST_activeDraftGoodsActivityAdd () {
      this.mixGallery();
      this.baseInfoForm.ticket_query_vos.forEach((item) => {
        if (item.multi_period === 1) {
          item.goods_ticket_date_vos.forEach((i) => {
            i.ticket_weekly = "";
          });
        }
      });
      var json = JSON.parse(JSON.stringify(this.baseInfoForm));
      json.goods_gallerys = json.goods_gallery_dos;

      // 票种格式化
      json.ticket_query_vos = JSON.stringify(json.ticket_query_vos);
      json.ticket_query_vos = json.ticket_query_vos.replace(
        /goods_ticket_date_vos/g,
        "ticket_date_vos"
      );
      json.ticket_query_vos = json.ticket_query_vos.replace(
        /goods_ticket_datetimes/g,
        "ticket_datetimes"
      );
      json.ticket_vos = JSON.parse(json.ticket_query_vos);
      console.log(json)
      // console.log()
      if (this.activeDraftGoodsActivityAddIsOk === true) {
        this.activeDraftGoodsActivityAddIsOk = false;
        API_activityDdraft.activeDraftGoodsActivityAdd(json)
          .then((res) => {
            // console.log(res);
            this.$message.success("保存草稿成功");
            this.$router.push({
              name: "活动草稿列表",
            });
            this.activeDraftGoodsActivityAddIsOk = true;
          })
          .catch((res) => {
            this.activeDraftGoodsActivityAddIsOk = true;
          });
      }
    },

    /**  获取活动分类列表 */
    GET_activeTypeList () {
      API_activityType.activeTypeList().then((res) => {
        // console.log(res);
        this.activityTypeListOriginal = [];
        for (var name in res) {
          this.activityTypeListOriginal.push(res[name]);
        }
        this.activityTypeList = [];
        this.activityTypeListOriginal.forEach((item1, index1) => {
          var jsonParent = {};
          jsonParent.value = item1.category_id;
          jsonParent.label = item1.name;
          var children = [];
          if (item1.children !== null) {
            item1.children.forEach((item2, index2) => {
              var jsonChild = {};
              jsonChild.value = item2.category_id;
              jsonChild.label = item2.name;
              children.push(jsonChild);
            });
          }
          jsonParent.children = children;
          this.activityTypeList.push(jsonParent);
        });
      });
    },

    /**  地址栏按钮 */
    address (type) {
      if (type === "new") {
        this.isNewAddress = !this.isNewAddress;
      } else if (type === "save") {
        if (this.addressNewParams.country_id === "") {
          this.$message.error("请选择地区！");
          return false;
        }
        if (this.addressNewParams.addr_detail === "") {
          this.$message.error("请填写详细地址！");
          return false;
        }
        // 新增地址
        API_activityAddress.activeAddressAdd(this.addressNewParams)
          .then((res) => {
            this.$message.success("新建地址成功");
            this.isNewAddress = false;
          })
          .then(() => {
            API_activityAddress.activeAddressShopList().then((res) => {
              this.activityAddressList = res;
              this.activityAddress = this.activityAddressList[0];
            }).then(() => {
              console.log(1111, this.activityAddress)
              this.activityAddressList.forEach((item) => {
                if (item.id === this.activityAddress.id) {
                  this.addressDetails = [
                    item.province,
                    item.city,
                    item.country,
                    item.addr_detail,
                  ].filter((s) => !!s).join("");
                  this.baseInfoForm.province_id = item.province_id;
                  this.baseInfoForm.city_id = item.city_id;
                  this.baseInfoForm.country_id = item.country_id;
                  this.baseInfoForm.addr_detail = item.addr_detail;
                  this.baseInfoForm.province = item.province;
                  this.baseInfoForm.city = item.city;
                  this.baseInfoForm.country = item.country;
                  console.log(this.baseInfoForm)
                }
              });
            })
          });
      } else if (type === "ok") {
        this.activityAddressList.forEach((item) => {
          if (item.id === this.activityAddress) {
            this.addressDetails = [
              item.province,
              item.city,
              item.country,
              item.addr_detail,
            ]
              .filter((s) => !!s)
              .join("");
            // console.log(item);
            this.baseInfoForm.province_id = item.province_id;
            this.baseInfoForm.city_id = item.city_id;
            this.baseInfoForm.country_id = item.country_id;
            this.baseInfoForm.addr_detail = item.addr_detail;
            this.baseInfoForm.province = item.province;
            this.baseInfoForm.city = item.city;
            this.baseInfoForm.country = item.country;
          }
        });
      }
    },

    /**  获取地址列表 */
    POST_activeAddressShopList () {
      API_activityAddress.activeAddressShopList().then((res) => {
        this.activityAddressList = res;
      });
    },

    /**  选择区域 */
    handleChangeArea (val) {
      const fields = ["province", "city", "country"];

      fields.forEach((field) => {
        this.addressNewParams[`${field}_id`] = 0;
        this.addressNewParams[`${field}`] = "";
      });

      val.forEach((item, index) => {
        this.addressNewParams[`${fields[index]}_id`] = item.id;
        this.addressNewParams[`${fields[index]}`] = item.local_name;
      });
      console.log(this.addressNewParams);
    },

    /**  删除活动或者能力标签 */
    tagClose (index, type) {
      if (type === "activity") {
        this.activityTagChooseList.splice(index, 1);
        this.baseInfoForm.label = "";
        this.activityTagChooseList.forEach((item, index) => {
          if (index === 0) {
            this.baseInfoForm.label += item.id;
          } else {
            this.baseInfoForm.label += "," + item.id;
          }
        });
      } else if (type === "ability") {
        this.abilityTagChooseList.splice(index, 1);
        this.baseInfoForm.label2 = "";
        this.abilityTagChooseList.forEach((item, index) => {
          if (index === 0) {
            this.baseInfoForm.label2 += item.id;
          } else {
            this.baseInfoForm.label2 += "," + item.id;
          }
        });
      }
    },

    /** 返回实际字符串长度 */
    returnStringLength (str) {
      let realLength = 0;
      const len = str.length;
      let charCode = -1;
      for (let i = 0; i < len; i++) {
        charCode = str.charCodeAt(i);
        if (charCode >= 0 && charCode <= 128) {
          realLength += 1;
        } else {
          realLength += 2;
        }
      }
      return realLength;
    },

    /**  新增能力标签,新增活动标签 */
    addNewTag (type) {
      var label_type = "";
      var label_name = "";
      if (type === "activity") {
        if (this.activityNewTag === "") {
          this.$message.error("请填写活动标签名称！");
          return false;
        }
        if (this.returnStringLength(this.activityNewTag) > 12) {
          this.$message.error("活动标签不超过6个汉字的长度");
          return false;
        }
        label_type = 1;
        label_name = this.activityNewTag;
      } else if (type === "ability") {
        if (this.abilityNewTag === "") {
          this.$message.error("请填写能力标签名称！");
          return false;
        }
        if (this.returnStringLength(this.abilityNewTag) > 8) {
          this.$message.error("能力标签不超过4个汉字的长度");
          return false;
        }
        label_type = 2;
        label_name = this.abilityNewTag;
      }
      API_activityTag.activeLabelAdd({
        label_type: label_type,
        label_name: label_name,
      }).then((res) => {
        if (type === "activity") {
          this.activityTagChooseList.push(res);
          this.activityNewTag = "";
          this.baseInfoForm.label = "";
          this.activityTagChooseList.forEach((item, index) => {
            if (index === 0) {
              this.baseInfoForm.label += item.id;
            } else {
              this.baseInfoForm.label += "," + item.id;
            }
          });
        } else if (type === "ability") {
          this.abilityTagChooseList.push(res);
          this.abilityNewTag = "";
          this.baseInfoForm.label2 = "";
          this.abilityTagChooseList.forEach((item, index) => {
            if (index === 0) {
              this.baseInfoForm.label2 += item.id;
            } else {
              this.baseInfoForm.label2 += "," + item.id;
            }
          });
        }
        this.POST_activeLabelShopList(label_type);
      });
    },

    /**  选中活动或者能力标签 */
    TagToChoose (row, type) {
      var noRepeat = true;
      if (type === "activity") {
        if (this.activityTagChooseList.length < 5) {
          this.activityTagChooseList.forEach((item) => {
            if (item.id === row.id) {
              noRepeat = false;
            }
          });
          if (noRepeat) {
            this.activityTagChooseList.push(row);
          }
        } else {
          this.$message.error("活动标签只能添加5个");
        }
        this.baseInfoForm.label = "";
        this.activityTagChooseList.forEach((item, index) => {
          if (index === 0) {
            this.baseInfoForm.label += item.id;
          } else {
            this.baseInfoForm.label += "," + item.id;
          }
        });
      } else if (type === "ability") {
        if (this.abilityTagChooseList.length < 3) {
          this.abilityTagChooseList.forEach((item) => {
            if (item.id === row.id) {
              noRepeat = false;
            }
          });
          if (noRepeat) {
            this.abilityTagChooseList.push(row);
          }
        } else {
          this.$message.error("能力标签只能添加3个");
        }
        this.baseInfoForm.label2 = "";
        this.abilityTagChooseList.forEach((item, index) => {
          if (index === 0) {
            this.baseInfoForm.label2 += item.id;
          } else {
            this.baseInfoForm.label2 += "," + item.id;
          }
        });
      }
    },
    travelerChange (el) {
      // console.log(el);
      // console.log(this.baseInfoForm.traveler_sign);
      if (el == 0) {//否
        this.baseInfoForm.traveler_write_limit = '';
      } else {
        this.baseInfoForm.traveler_write_limit = this.travelerList.join("");
      }
    },
    /**  出行人信息 */
    traveler (index) {
      return
      if (this.isUp) {
        return false;
      }
      if (this.travelerList[index] === 0) {
        this.travelerList.splice(index, 1, 1);
      } else if (this.travelerList[index] === 1) {
        this.travelerList.splice(index, 1, 0);
      }
      this.baseInfoForm.traveler_write_limit = this.travelerList.join("");
    },

    /**  上传视频前校验 */
    upLoadVideoBefore (file) {
      console.log(file.name.split(".")[1]);
      if (file.name.split(".")[1] !== "mp4") {
        this.$message.error("请上传mp4文件");
        return false;
      } else {
        const isVideo = file.type.indexOf("video") > -1;
        const isLt100M = file.size / 1024 / 1024 < 50;
        if (!isVideo) {
          this.$message.error("只能上传视频");
        }
        if (!isLt100M) {
          this.$message.error("上传视频不大于50M");
        }
        return isVideo && isLt100M;
      }
    },

    /** 上传中 */
    uploadLoading (file) {
      this.videoProgress = parseInt(file.percent);
      if (file.percent !== 100) {
        this.videoUpload = true;
      } else {
        this.videoUpload = false;
      }
    },

    /**  上传视频 */
    upLoadVideoSuccess (res, file) {
      this.loading = false;
      this.baseInfoForm.video = file.response.url;
    },

    /** 删除视频 */
    videoRemove () {
      this.baseInfoForm.video = "";
    },

    /**  选择活动分类 */
    activityTypeChange (val) {
      if (val[1]) {
        this.baseInfoForm.category_id2 = val[1];
      } else {
        this.baseInfoForm.category_id2 = "";
      }
      this.baseInfoForm.category_id = val[0];
      this.baseInfoForm.category_first = "";
      this.baseInfoForm.category_second = "";
      this.activityTypeList.forEach((item1) => {
        if (item1.value === val[0]) {
          this.baseInfoForm.category_first = item1.label;
          if (val[1]) {
            item1.children.forEach((item2) => {
              if (item2.value === val[1]) {
                this.baseInfoForm.category_second = item2.label;
              }
            });
          } else {
            if (item1.children.length !== 0) {
              this.baseInfoForm.category_id2 = "";
              this.baseInfoForm.category_id = "";
              this.baseInfoForm.category_first = "";
              this.baseInfoForm.category_second = "";
              this.activityTypeChoose = [];
              this.$message.error("该分类有二级分类，请选择二级分类");
              return false;
            }
          }
        }
      });
    },

    /**  选择活动时长 */
    activityTimeChange () {
      if (this.activityTime.activityTimeType === "1") {
        this.baseInfoForm.duration_day = this.activityTime.activityTimeDay;
        this.baseInfoForm.activity_duration = Math.ceil(
          Number(this.activityTime.activityTimeDay) * 24
        );
        this.baseInfoForm.duration_hour = 0;
      } else if (this.activityTime.activityTimeType === "2") {
        this.baseInfoForm.duration_hour = this.activityTime.activityTimeHour;
        this.baseInfoForm.activity_duration = this.activityTime.activityTimeHour;
        this.baseInfoForm.duration_day = 0;
      } else {
        this.baseInfoForm.activity_duration = "";
      }
    },

    /** 获取平台标签标签 */
    POST_activeLabelList (type) {
      API_activityTag.activeLabelList({ label_type: type }).then((res) => {
        // console.log(res);
        if (type === 1) {
          res.forEach((item) => {
            if (item.shop_id === 0) {
              this.activityTagPlatformList.push(item);
            }
          });
        } else if (type === 2) {
          res.forEach((item) => {
            if (item.shop_id === 0) {
              this.abilityTagPlatformList.push(item);
            }
          });
        }
      });
    },
    /** 获取历史标签 */
    POST_shopLabelList (type) {
      API_activityTag.getShopTag({
        label_type: type,
        shop_id: this.shopInfo.shop_id,
      }).then((res) => {
        // console.log(res);
        if (type === 1) {
          res.forEach((item) => {
            this.activityTagList.push(item);
          });
        } else if (type === 2) {
          res.forEach((item) => {
            this.abilityTagList.push(item);
          });
        }
      });
    },

    /**  查询导师列表 */
    POST_activeTeacherShopList () {
      API_teacher.activeTeacherShopList().then((res) => {
        this.activityTutorList = res;
        // console.log(res);
      });
    },

    /** 选择活动日期 */
    ticketPeriodData (ticketTypeIndex, ticketPeriodIndex) {
      // console.log('ticketPeriodData', this.baseInfoForm);

      const ticket = this.baseInfoForm.ticket_query_vos[ticketTypeIndex];
      const { ticketPeriodDates } = this.ticketTypeList[ticketTypeIndex];
      const period = ticketPeriodDates[ticketPeriodIndex];
      const periodDates = period.dates;

      const { goods_ticket_date_vos } = ticket;
      const currentDate = goods_ticket_date_vos[ticketPeriodIndex];

      const isPeriodDatesExists = periodDates != null;

      if (isPeriodDatesExists) {
        const isMultiPeriod = ticket.multi_period === 1;

        if (isMultiPeriod) {
          const isSameDates = periodDates[1] === periodDates[0];

          if (isSameDates) {
            currentDate.isShowSelectData = 1;
            currentDate.goods_ticket_datetimes.forEach((item) => {
              item.time_start = "00:00";
              item.time_end = "23:59";
            });
            currentDate.start_date = periodDates[0] / 1000;
            currentDate.end_date = periodDates[1] / 1000;
            this.$forceUpdate();
          } else {
            currentDate.isShowSelectData = 0;
            currentDate.start_date = periodDates[0] / 1000;
            currentDate.end_date = periodDates[1] / 1000;
            this.$forceUpdate();
          }
        } else {
          currentDate.start_date = periodDates[0] / 1000;
          currentDate.end_date = periodDates[1] / 1000;
          this.tickerTimeButton = false;
        }
        // if (
        //   periodDates[1] / 1000 -
        //     periodDates[0] / 1000 >
        //   30 * 86400
        // ) {
        //   this.$message.error("活动日期间隔不得大于30天");
        //   this.tickerTimeButton = true;
        // } else {
        // }
      } else {
        currentDate.start_date = "";
        currentDate.end_date = "";
      }

      const isFirstPeriod = ticketPeriodIndex === 0;

      if (ticketPeriodIndex === 0 && isPeriodDatesExists) {
        const day7 = 7 * 24 * 60 * 60 * 1000;
        const periodDatesDt = periodDates[1] - periodDates[0];

        if (periodDatesDt < day7) {
          const showDayList = new Array(7).fill(false);
          period.dayList = [...showDayList];

          for (let i = 0; i < 7; i++) {
            if (periodDates[0] + 24 * 60 * 60 * 1000 * i <= periodDates[1]) {
              const getDay = new Date(periodDates[0] + 24 * 60 * 60 * 1000 * i).getDay();
              if (getDay > 0) {
                showDayList[getDay - 1] = true;
              } else {
                showDayList[6] = true;
              }
            }
          }
          period.showDayList = showDayList;
        } else {
          period.showDayList = new Array(7).fill(false);
        }
        period.dayList = [...period.showDayList];
      } else {
        period.showDayList = new Array(7).fill(false);
        period.dayList = new Array(7).fill(false);
      }
      currentDate.ticket_weekly = period.dayList.reduce((list, item, index) => {
        if (item) list.push(index + 1);
        return list;
      }, []).join('|');
    },

    /** 选择活动时间 */
    ticketPeriodTime (ticketTypeIndex, ticketPeriodIndex, ticketTimeIndex) {
      const { goods_ticket_date_vos } = this.baseInfoForm.ticket_query_vos[
        ticketTypeIndex
      ];
      const { goods_ticket_datetimes } = goods_ticket_date_vos[
        ticketPeriodIndex
      ];

      const { ticketPeriodDates } = this.ticketTypeList[ticketTypeIndex];
      const { ticketPeriodTimes } = ticketPeriodDates[ticketPeriodIndex];
      const {
        ticketHourLow,
        ticketMinLow
      } = ticketPeriodTimes[
        ticketTimeIndex
        ];

      const {
        ticketHourHigh,
        ticketMinHigh
      } = ticketPeriodTimes[
        ticketTimeIndex
        ];

      goods_ticket_datetimes[ticketTimeIndex].time_start =
        ticketHourLow + ":" + ticketMinLow;
      if (ticketHourHigh !== "00") {
        if (
          ticketHourHigh < ticketHourLow ||
          (ticketHourHigh === ticketHourLow && ticketMinLow > ticketMinHigh)
        ) {
          this.$message.error("检查输入的活动时间");
          this.tickerTimeButton = true;
        } else {
          this.tickerTimeButton = false;
          goods_ticket_datetimes[ticketTimeIndex].time_end =
            ticketHourHigh + ":" + ticketMinHigh;
        }
      }
    },

    /** 期次类型改变 */
    periodType (ticketTypeIndex) {
      // 2021-3-4 修复期次切换报错的问题
      const ticket = this.baseInfoForm.ticket_query_vos[ticketTypeIndex];
      let ticketPeriodJson = {};
      if (ticket.multi_period === 0) {
        ticketPeriodJson = {
          ticket_num: 0,
          start_date: "",
          end_date: "",
          goods_ticket_datetimes: [
            {
              time_start: "00:00",
              time_end: "00:00",
              tpeople_num: "",
              tpeople_use_num: "",
            },
          ],
          ticket_weekly: "",
        };
      } else if (
        ticket.multi_period === 1
      ) {
        ticketPeriodJson = {
          ticket_num: 1,
          start_date: "",
          end_date: "",
          goods_ticket_datetimes: [
            {
              time_start: "00:00",
              time_end: "00:00",
              tpeople_num: "",
              tpeople_use_num: "",
            },
          ],
          ticket_weekly: "",
        };
      }
      const ticketPeriodListJson = {
        dates: [],
        ticketPeriodTimes: [
          {
            ticketHourLow: "00",
            ticketMinLow: "00",
            ticketHourHigh: "00",
            ticketMinHigh: "00",
          },
        ],
        dayList: [false, false, false, false, false, false, false],
        showDayList: [true, true, true, true, true, true, true],
        count: "",
      };
      this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos = [ticketPeriodJson];
      this.ticketTypeList[ticketTypeIndex].ticketPeriodDates = [];
      this.ticketTypeList[ticketTypeIndex].ticketPeriodDates.push(
        ticketPeriodListJson
      );
    },

    /** 新增和删除票种 */
    addAndDelTicketType (type, ticketTypeIndex) {
      if (type === "add") {
        const ticketTypeJson = {
          ticket_name: "",
          ticket_info: "",
          fee_info: "",
          mktprice: "",
          price: "",
          single_sale_num: "",
          limit_sign: 0,
          buy_limit_num: "",
          multi_period: 0,
          goods_ticket_date_vos: [
            {
              ticket_num: 0,
              start_date: "",
              end_date: "",
              goods_ticket_datetimes: [
                {
                  time_start: "00:00",
                  time_end: "00:00",
                  tpeople_num: "",
                  tpeople_use_num: "",
                },
              ],
              ticket_weekly: "",
            },
          ],
        };
        const ticketTypeListJson = {
          ticketPeriodDates: [
            {
              dates: [],
              ticketPeriodTimes: [
                {
                  ticketHourLow: "00",
                  ticketMinLow: "00",
                  ticketHourHigh: "00",
                  ticketMinHigh: "00",
                },
              ],
              dayList: [false, false, false, false, false, false, false],
              showDayList: [true, true, true, true, true, true, true],
              count: "",
            },
          ],
        };
        this.baseInfoForm.ticket_query_vos.push(ticketTypeJson);
        this.ticketTypeList.push(ticketTypeListJson);
      } else if (type === "del") {
        this.baseInfoForm.ticket_query_vos.splice(ticketTypeIndex, 1);
        this.ticketTypeList.splice(ticketTypeIndex, 1);
        this.mktpriceChange();
        this.priceChange();
      }
    },

    /** 多期新增或删除期次 */
    addAndDelTicketPeriod (type, ticketTypeIndex, ticketPeriodIndex) {
      if (type === "add") {
        const ticketPeriodJson = {
          ticket_num: 0,
          start_date: "",
          end_date: "",
          isShowSelectData: 1,
          goods_ticket_datetimes: [
            {
              time_start: "00:00",
              time_end: "00:00",
              tpeople_num: "",
              tpeople_use_num: "",
            },
          ],
          ticket_weekly: "",
        };
        const ticketPeriodListJson = {
          dates: [],
          ticketPeriodTimes: [
            {
              ticketHourLow: "00",
              ticketMinLow: "00",
              ticketHourHigh: "00",
              ticketMinHigh: "00",
            },
          ],
          count: "",
        };
        this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos.push(ticketPeriodJson);
        this.ticketTypeList[ticketTypeIndex].ticketPeriodDates.push(ticketPeriodListJson);
        this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos.forEach((item, index) => {
          item.ticket_num = index + 1;
        });
      } else if (type === "del") {
        this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos.splice(ticketPeriodIndex, 1);
        this.ticketTypeList[ticketTypeIndex].ticketPeriodDates.splice(ticketPeriodIndex, 1);
        this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos.forEach((item, index) => {
          item.ticket_num = index + 1;
        });
      }
    },

    /** 多期新增或删除时间 */
    addAndDelticketTime (
      type,
      ticketTypeIndex,
      ticketPeriodIndex,
      ticketTimeIndex
    ) {
      if (type === "add") {
        const ticketTimeJson = {
          time_start: "00:00",
          time_end: "00:00",
          tpeople_num: "",
          tpeople_use_num: "",
        };
        const ticketTimeListJson = {
          ticketHourLow: "00",
          ticketMinLow: "00",
          ticketHourHigh: "00",
          ticketMinHigh: "00",
        };
        this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos[ticketPeriodIndex].goods_ticket_datetimes.push(ticketTimeJson);
        this.ticketTypeList[ticketTypeIndex].ticketPeriodDates[ticketPeriodIndex].ticketPeriodTimes.push(ticketTimeListJson);
        this.countChange(ticketTypeIndex, ticketPeriodIndex);
      } else if (type === "del") {
        this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos[ticketPeriodIndex].goods_ticket_datetimes.splice(ticketTimeIndex, 1);
        this.ticketTypeList[ticketTypeIndex].ticketPeriodDates[ticketPeriodIndex].ticketPeriodTimes.splice(ticketTimeIndex, 1);
      }
    },

    /** 单期选择日子 */
    day (ticketTypeIndex, dayIndex, type) {
      if ((this.isUp && !this.periodEditor.isEditing) || type === false) {
        return false;
      }

      const src = !this.ticketTypeList[ticketTypeIndex].ticketPeriodDates[0]
        .dayList[dayIndex];
      this.ticketTypeList[ticketTypeIndex].ticketPeriodDates[0].dayList.splice(
        dayIndex,
        1,
        src
      );
      const strList = [];
      this.ticketTypeList[ticketTypeIndex].ticketPeriodDates[0].dayList.forEach(
        (item, index) => {
          if (item === true) {
            strList.push(index + 1);
          }
        }
      );

      this.baseInfoForm.ticket_query_vos[
        ticketTypeIndex
      ].goods_ticket_date_vos[0].ticket_weekly = strList.join("|");
    },

    /** 单期可售数量 */
    countChange (ticketTypeIndex, ticketPeriodIndex) {
      this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos[
        ticketPeriodIndex
      ].sale_num = this.ticketTypeList[ticketTypeIndex].ticketPeriodDates[
        ticketPeriodIndex
      ].count;

      this.baseInfoForm.ticket_query_vos[ticketTypeIndex].goods_ticket_date_vos[
        ticketPeriodIndex
      ].goods_ticket_datetimes.forEach((item) => {
        item.tpeople_num = this.ticketTypeList[
          ticketTypeIndex
        ].ticketPeriodDates[ticketPeriodIndex].count;
        item.tpeople_use_num = this.ticketTypeList[
          ticketTypeIndex
        ].ticketPeriodDates[ticketPeriodIndex].count;
      });
    },

    /** 原价变化 */
    mktpriceChange () {
      let min = "";
      let max = "";
      this.baseInfoForm.ticket_query_vos.forEach((item, index) => {
        if (index === 0) {
          min = Number(item.mktprice);
          max = Number(item.mktprice);
        } else {
          if (Number(item.mktprice) > max) {
            max = Number(item.mktprice);
          }
          if (Number(item.mktprice) < min) {
            min = Number(item.mktprice);
          }
        }
      });
      this.baseInfoForm.mkt_low = min;
      this.baseInfoForm.mkt_high = max;
      // console.log(min + "-" + max)
    },

    /** 销售价变化 */
    priceChange () {
      var min = "";
      var max = "";
      this.baseInfoForm.ticket_query_vos.forEach((item, index) => {
        if (index === 0) {
          min = Number(item.price);
          max = Number(item.price);
        } else {
          if (Number(item.price) > max) {
            max = Number(item.price);
          }
          if (Number(item.price) < min) {
            min = Number(item.price);
          }
        }
      });
      this.baseInfoForm.price_low = min;
      this.baseInfoForm.price_top = max;
    },

    /** 预定截止时间类型改变 */
    endTimeFn (type) {
      if (type === "2") {
        this.baseInfoForm.buy_limit_sign = this.endTime;
      } else {
        this.baseInfoForm.buy_limit_sign = type;
      }
    },

    /** 预定截止时间天数和小时改变 */
    endTimesChange () {
      if (this.defaultChecked === "1") {
        if (this.endTimes.day % 1 !== 0) {
          this.$message.error("天数只能输入整数");
          this.tickerTimeButton = true;
        } else {
          this.baseInfoForm.buy_limit_hour = "";
          this.endTimes.hour = "";
          this.baseInfoForm.buy_limit_day = this.endTimes.day;
          this.tickerTimeButton = false;
        }
      } else {
        if (this.endTimes.hour % 1 !== 0) {
          this.$message.error("小时数只能输入整数");
          this.tickerTimeButton = true;
        } else if (this.endTimes.hour < 0 || this.endTimes.hour > 23) {
          this.$message.error("活动小时区间为0-23");
          this.tickerTimeButton = true;
        } else {
          this.baseInfoForm.buy_limit_hour = this.endTimes.hour;
          this.baseInfoForm.buy_limit_day = "";
          this.endTimes.day = "";
          this.tickerTimeButton = false;
        }
      }
      console.log(this.baseInfoForm.buy_limit_day, this.baseInfoForm.buy_limit_hour)
      // if (this.endTimes.day === "") {
      //   day = 0;
      // } else {
      //   day = Number(this.endTimes.day);
      // }
      // if (this.endTimes.hour === "") {
      //   hour = 0;
      // } else {
      //   hour = Number(this.endTimes.hour);
      // }
      // this.baseInfoForm.buy_limit_hour = day * 24 + hour;
    },

    /** 新增或删除自定义退款条件 */
    addAndDelDiys (type, index) {
      if (type === "add") {
        const json = {
          refund_point: "",
          time_gte: "",
          time_lte: "",
        };
        this.baseInfoForm.diys.push(json);
      } else if (type === "del") {
        this.baseInfoForm.diys.splice(index, 1);
      }
    },

    /** 新增或删除活动亮点 */
    addAndDelLight (type, index) {
      if (type === "add") {
        this.baseInfoForm.lights.push({
          light_intro: "",
        });
      } else if (type === "del") {
        this.baseInfoForm.lights.splice(index, 1);
      }
    },

    /** 新增或删除活动行程 */
    addAndDelTrips (type, index) {
      if (type === "add") {
        this.baseInfoForm.trips.push({
          trip_title: "",
          trip_intro: "",
        });
      } else if (type === "del") {
        this.baseInfoForm.trips.splice(index, 1);
      }
    },

    /** 新增或删除参加须知 */
    addAndDelNotices (type, index) {
      if (type === "add") {
        this.baseInfoForm.notices.push({
          notice_title: "",
          notice_intro: "",
        });
      } else if (type === "del") {
        this.baseInfoForm.notices.splice(index, 1);
      }
    },

    /** 退款类型改变 */
    refundTypeChange () {
      this.baseInfoForm.diys = [
        {
          refund_point: "",
          time_gte: "",
          time_lte: "",
        },
      ];
    },

    /** 复制票种 */
    copyTicket (item, index) {
      const copyTicketTypeList = JSON.parse(
        JSON.stringify(this.ticketTypeList[index])
      );
      const copyTicketQueryVos = JSON.parse(JSON.stringify(item));
      this.ticketTypeList.push(copyTicketTypeList);
      this.baseInfoForm.ticket_query_vos.push(copyTicketQueryVos);
      // console.log(this.baseInfoForm.ticket_query_vos);
      // console.log(this.ticketTypeList);
    },

    /** 选中导师变化 */
    teacherChange () {
      // console.log(this.teacherChooseList)
      var teacher_ids = "";
      this.teacherChooseList.forEach((item, index) => {
        if (index === 0) {
          teacher_ids += item;
        } else {
          teacher_ids += "," + item;
        }
      });
      if (teacher_ids === "") {
        this.baseInfoForm.teacher_ids = null;
      } else {
        this.baseInfoForm.teacher_ids = teacher_ids;
      }
    },

    /** 选择标签失去焦点触发 */
    blur () {
      this.activityTagShow = false;
      this.abilityTagShow = false;
    },

    /** 选择标签得到焦点触发 */
    focus (type) {
      if (type === 1) {
        this.activityTagShow = true;
      } else if (type === 2) {
        this.abilityTagShow = true;
      }
    },

    /** 校验手机号是不是输入数字 */
    phone1Change () {
      this.baseInfoForm.service_phone1 = this.baseInfoForm.service_phone1.replace(
        /[^\d-]/g,
        ""
      );
    },
    phone2Change () {
      this.baseInfoForm.service_phone2 = this.baseInfoForm.service_phone2.replace(
        /[^\d]/g,
        ""
      );
    },

    /** 活动时长点击事件 */
    activityTimeClick (type) {
      if (type === 0) {
        this.activityTime.activityTimeType = "1";
        this.activityTime.activityTimeHour = "";
      } else if (type === 1) {
        this.activityTime.activityTimeType = "2";
        this.activityTime.activityTimeDay = "";
      }
    },

    /** 孩子年龄点击事件 */
    childLimitSignClick (type) {
      if (type === 1) {
        this.baseInfoForm.child_limit_gte = "";
        this.baseInfoForm.child_limit_lt = "";
      }
    },

    /** 展示图片说明 */
    showImgExplain () {
      this.imgExplain = true;
    },

    log (...args) {
      console.log(...args);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: white;
}

// 文本框
// /deep/ .text-content{
//   min-height: 500px;
//   border: 1px solid #dddddd;
//   border-radius: 8px;
//   color: #666666;
// }
// 进度条位置
/deep/ .el-progress {
  margin-top: 30px;
}

/deep/ .box_border {
  border: 2px solid #007f7f !important;
}

/deep/ .stepword {
  color: #007f7f !important;
}

/deep/ .step {
  background: #007f7f !important;
}

/deep/ .step_div {
  width: 96%;
  height: 150px;
  background: rgba(0, 127, 127, 0.1);
  border-radius: 12px;
  padding-left: 10%;
  padding-right: 10%;
  margin-left: 2%;
  margin-right: 2%;

  .step1,
  .step2,
  .step3 {
    opacity: 1;

    .line {
      height: 8px;
      // background: #007F7F;
      background: #dddddd;
    }

    .box1 {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      padding: 0;
      border: 2px solid #dddddd;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .box2 {
        width: 48px;
        height: 48px;
        line-height: 48px;
        border-radius: 50%;
        margin: 2px 2px;
        border: 0;
        font-size: 30px;
        color: white;
        // background: #007F7F;
        background: #dddddd;
      }
    }

    .tishixinxi {
      font-size: 18px;
      font-weight: bold;
      color: #dddddd;
      position: relative;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 150%);
      width: 300px;
    }
  }
}

/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;

  .el-form-item {
    width: 100%;
    text-align: left;
  }
}

/*平铺*/
div.base-info-item>div {
  margin-left: 5%;
}

div.base-info-item {
  .step1_title {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;

    h4 {
      margin-bottom: 10px;
      padding: 0 10px;
      margin-top: 0;
      // border: 1px solid #ddd;
      // background-color: #f8f8f8;
      font-weight: bold;
      color: #212121;
      font-size: 18px;
      // line-height: 40px;
      text-align: left;
    }
  }
}

/**  底部步骤 */
.footer {
  width: calc(100vw - 220px);
  padding: 10px 0;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 9999;
}

/* 详细地址 */
.addressBox {
  width: 600px;
  background: rgba(246, 246, 246, 1);
  border-radius: 8px;
}

/* 添加新地址 */
/* 添加新活动标签 */
/* 添加新能力标签 */
/* 添加出行人标签* / /* 退款金额设置盒子 */
.addressNewBox,
.activityTagBox,
.abilityTagBox,
.travelerTagBox,
.refundPriceBox {
  width: 630px;
  border: 2px dashed rgba(221, 221, 221, 1);
  border-radius: 8px;
  padding: 24px 0;
  margin-top: 10px;
}

.travelerTagBox {
  background: #fff;
}

/* 标签盒子 */
.tagBox {
  display: flex;
  flex-wrap: wrap;
  width: 460px;

  .tag {
    margin-right: 10px;
    margin-bottom: 10px;
    background: #008080;
    color: #fff;
    cursor: pointer;
  }

  .tagNo {
    margin-right: 10px;
    margin-bottom: 10px;
    color: #9a9a9a;
    border: 1px solid #aaa;
    background: #fff;
    cursor: pointer;
  }

  .tagYes {
    color: #ffffff;
    border: 1px solid #008080;
    cursor: pointer;
    background: #008080;
  }
}

/* 图片盒子 */
.activityImgBox {
  width: 660px;
  background: rgba(246, 246, 246, 1);
  border-radius: 8px;
  padding: 24px;
  // display: flex;
  flex-wrap: wrap;

  .imgBox {
    width: 110px;
    height: 135px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    position: relative;

    .photo {
      width: 110px;
      height: 110px;
      background: #fff;
    }

    .imgDel {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }

    .imgButton {
      color: #999;
      border: 1px solid #aaa;
      background: #fff;
      margin-right: 10px;
      font-size: 14px;
      padding: 4px 5px;
    }

    .imgButton:hover {
      color: #008080;
      border: 1px solid #008080;
    }
  }
}

/* 上传区域图标 */
.activityUploadBox {
  width: 110px;
  height: 110px;
  border: 1px dashed rgba(221, 221, 221, 1);
  border-radius: 4px;
  background: rgba(247, 250, 250, 1);

  i {
    display: block;
    margin: 26px auto 12px;
    color: #999;
    font-size: 33px;
  }

  div {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #999;
  }
}

/* 视频盒子 */
.activityVideoBox {
  width: 600px;
  background: rgba(246, 246, 246, 1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: flex-end;

  /deep/ .imgDel {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 120px;
    top: 34px;
    cursor: pointer;
  }
}

/* 说明文字 */
.explain {
  font-size: 14px;
  line-height: 20px;
  color: #888;
  margin-left: 10px;
}

/* 视频显示区域 */
.activityUploadVideoBox {
  width: 110px;
  height: 110px;

  video {
    width: 100%;
    height: 100%;
  }
}

/* 票种设置盒子 */
.ticketTypeBox {
  margin-bottom: 30px;

  .ticketTypeTopBox {
    width: 640px;
    background: rgba(255, 255, 255, 1);
    border: 1px dashed rgba(0, 128, 128, 1);
    border-radius: 8px 8px 0px 0px;
    border-bottom: none;
    padding: 18px;

    .ticketTypeTopTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;

        div {
          width: 6px;
          height: 6px;
          background: rgba(0, 128, 128, 1);
          border-radius: 50%;
        }

        span {
          display: block;
          font-size: 18px;
          line-height: 18px;
          font-weight: bold;
          color: rgba(0, 128, 128, 1);
          margin-left: 5px;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .copyButton {
          font-size: 14px;
          line-height: 14px;
          color: rgba(52, 113, 205, 1);
          height: 14px;
          padding: 0;
        }

        .delButton {
          font-size: 14px;
          line-height: 14px;
          color: rgba(254, 85, 88, 1);
          height: 14px;
          padding: 0;
        }
      }
    }

    .ticketTypeIpt {
      margin-top: 20px;

      /deep/ .el-input__inner {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #ddd;
        padding-left: 0;
        font-size: 15px;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .name {
        font-size: 14px;
        color: #666;
      }

      .inpBox {
        display: flex;

        .inp {
          width: 60px;

          /deep/ .el-input__inner {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #ddd;
            padding-left: 0;
            font-size: 15px;
            color: #222;
          }
        }

        div {
          font-size: 16px;
          color: #222;
        }
      }

      .selectBox {
        width: 95px;

        /deep/ .el-input__inner {
          border-radius: 0;
          border: none;
          border-bottom: 1px solid #ddd;
          padding-left: 0;
          font-size: 15px;
          color: #222;
        }
      }
    }
  }

  .ticketTypeBottomBox {
    width: 640px;
    background: rgba(255, 255, 255, 1);
    border: 1px dashed #ddd;
    border-radius: 0px 0px 8px 8px;
    padding: 20px;

    .ticketTypeBottom {
      border-top: 1px solid #ddd;
      padding-top: 20px;
      margin-top: 20px;

      .titleBox {
        display: flex;
        align-items: center;

        img {
          width: 14px;
          margin-right: 10px;
        }

        div {
          font-size: 16px;
          line-height: 16px;
          font-weight: bold;
          color: rgba(0, 128, 128, 1);
        }
      }

      .dateAndTimeBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        .formItem {
          margin-bottom: 0;

          /deep/ .el-form-item__label {
            width: 100px !important;
            padding: 0;
            text-align: left;
          }
        }

        .timeBox {
          display: flex;
          width: 300px;

          .colon {
            padding: 0 5px;
          }

          .to {
            color: #999;
            padding: 0 5px;
          }
        }
      }

      .dayBox {
        margin-top: 30px;

        .tagBox {
          display: flex;

          .tag {
            color: #999;
            border: 1px solid #aaa;
            background: #fff;
            margin-right: 10px;
          }

          .act {
            color: #ffffff;
            border: 1px solid #008080;
            background: #008080;
          }
        }

        .formItem {
          margin-bottom: 0;

          /deep/ .el-form-item__label {
            width: 90px !important;
            padding: 0;
            text-align: left;
          }
        }
      }
    }

    .noBorder {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }
  }
}

/* 预定截止时间盒子 */
.endTimeBox {
  display: flex;
  flex-direction: column;

  .endTimeRadio {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .endTimeBox1 {
    display: flex;
    width: 600px;

    .select {
      width: 130px;
    }

    .inpBox {
      margin-left: 35px;
      display: flex;
      align-items: center;

      .endTimeIpt {
        width: 60px;

        /deep/ .el-input__inner {
          width: 60px;
        }
      }
    }
  }
}

/* 退款金额设置盒子 */
.refundPriceBox {
  padding: 24px 24px 0;

  .box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .left {
      display: flex;
      width: 560px;

      .text {
        font-size: 14px;
        color: #666;
        text-align: center;
      }

      .soldCountInp {
        width: 60px !important;

        /deep/ .el-input__inner {
          width: 60px !important;
        }
      }
    }
  }
}

/* 活动详情盒子 */
.activityDetails {
  width: 100%;

  .buttonBox {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
    }
  }

  .info {
    font-size: 14px;
    color: #888;
    margin-top: 20px;

    span {
      font-size: 14px;
      color: #fe5558;
    }
  }
}

/* 活动亮点盒子 */
/* 活动行程盒子 */
.characteristicBox,
.tripBox {
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin-bottom: 10px;
}

/* 活动行程盒子 */
.tripBox {
  .tripContentBox {
    border: 1px dashed rgba(221, 221, 221, 1);
    border-radius: 8px;
    padding: 20px 10px;
    display: flex;
    width: 560px;

    .left {
      width: 30px;
      line-height: 74px;
      text-align: center;
      background: #008080;
      color: #fff;
      margin-right: 10px;
      border-radius: 8px;
    }

    .right {
      flex: 1;

      .inp {
        /deep/ .el-input__inner {
          color: #606266;
        }

        /deep/ .el-textarea__inner {
          color: #606266;
        }
      }
    }
  }
}

.width {
  width: 400px;
}

.imgExplain {
  // display: flex;

  img {
    width: 20px;
    height: 20px;
  }

  span {
    font-size: 14px;
    line-height: 22px;
    color: #999999;
    margin-left: 10px;
  }
}
</style>
<style lang="scss">
.el-form-item__label {
  word-break: break-all;
}

.urgentBox {
  .el-form-item__label {
    width: 160px !important;
  }
}
</style>

/**
 * 活动分类相关API
 */

import request from '@/utils/request'

/**
 * 查询活动分类列表
 */
export function activeTypeList() {
  return request({
    url: '/active/goodsActivity',
    method: 'get'
  })
}

/**
 * 查询所有分类列表
 */
export function sellerGoodsCategory(category_id) {
  return request({
    url: `/seller/goods/category/${category_id}`,
    method: 'get'
  })
}

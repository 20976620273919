/**
 * 活动地址相关API
 */

import request from '@/utils/request'

/**
 * 添加活动地址
 * @param params
 */
export function activeAddressAdd(params) {
  return request({
    url: '/active/address/add',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 查询活动地址
 * @param params
 */
export function activeAddressShopList(params) {
  return request({
    url: '/active/address/shop_list',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 查询活动地址带分页
 * @param params
 */
export function activeAddressShopPage(params) {
  return request({
    url: '/active/address/shop_page',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 编辑活动地址
 * @param params
 */
export function activeAddressEdit(params) {
  return request({
    url: '/active/address/edit',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 删除活动地址
 * @param id
 */
export function activeAddressDel(id) {
  return request({
    url: `/active/address/${id}`,
    method: 'delete',
    loading: false
  })
}
